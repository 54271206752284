import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormHandles } from "@unform/core";
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router-dom';

import DegradeLine from '../../../components/DegradeLine';
import FormBuilder from '../../../components/Forms/FormBuilder';
import api from '../../../services/api';
import logo from '../../../assets/wendata-logo.png';
import {
    AnimationContainer,
    Body,
    Bottom,
    BottomLeft,
    BottomRight,
    BtnStart,
    Container,
    ContainerGroup,
    Content,
    ContentList,
    GroupTag,
    GroupTagContainer,
    GroupTagIcon,
    GroupTagName,
    Header,
    HeaderLeft,
    HeaderRight,
    ProgressBar,
    ProgressBarPercent
} from './styles';
import { FieldProps, Fields } from '../../../components/Forms/Fields/FieldBuilder';
import { Onboarding } from '../../../interfaces/Onboarding';
import { useToast } from '../../../hooks/toast';
import { useMediaQuery } from 'react-responsive';
import { IconList, IconPickerItem } from 'react-fa-icon-picker';
import { IoMdListBox } from 'react-icons/io';

interface RouteParams {
    hash?: string;
}

const fieldsProfissionalForm: FieldProps[] = [
    {
        name: "company_name_onboarding",
        type: Fields.TEXT_SHORT_FIELD,
        required: true,
        index: 1,
        title: "Qual é o nome da sua empresa?",
        description: "",
        placeholder: "Digite aqui...",
        validation_type: "string",
        validations: [
            {
                type: "required",
                params: ["É necessário que seja preenchido o campo!"]
            },
            {
                type: "min",
                params: [1, "O nome da sua empresa deve conter mais de 1 letra!"]
            }
        ]
    }
]

interface SelectOneItem {
    id: number;
    name: string;
    icon: IconList;
    color: string;
    itemsGroup?: SelectOneItem[];
}

const teamSizeItems: SelectOneItem[] = [
    {
        id: 1,
        name: "Somente eu",
        icon: "FaRegUser",
        color: "#00bcd4"
    },
    {
        id: 2,
        name: "2-10",
        icon: "FaUsers",
        color: "#00bcd4"
    },
    {
        id: 3,
        name: "11-50",
        icon: "FaHome",
        color: "#00bcd4"
    },
    {
        id: 4,
        name: "51-200",
        icon: "FaStoreAlt",
        color: "#00bcd4"
    },
    {
        id: 5,
        name: "201-500",
        icon: "FaRegBuilding",
        color: "#00bcd4"
    },
    {
        id: 6,
        name: "501 ou mais",
        icon: "FaIndustry",
        color: "#00bcd4"
    }
]

interface formProps {
    company_name_onboarding: string;
    company_size_onboarding: string;
}

const CompanyData: React.FC = () => {

    const { params } = useRouteMatch<RouteParams>();
    const formRef = useRef<FormHandles>(null);
    const { addToast } = useToast();
    const history = useHistory();
    const [onboarding, setOnboarding] = useState<Onboarding>();
    const [loading, setLoading] = useState<boolean>(false);
    const [isPRofessional, setIsProfessional] = useState<boolean>(true);
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [selectedTeamSize, setSelectedTeamSize] = useState<SelectOneItem>();

    const handleSubmitPage = useCallback(async () => {

        if (isPRofessional) {
            if (formRef.current !== undefined) {
                formRef.current?.submitForm();
            }
        } else {

            if (selectedTeamSize !== undefined) {
                setLoading(true);
                await api
                    .post('/onboarding/', {
                        status: "3",
                        email: onboarding?.email,
                        hash: params.hash,
                        company_name: "",
                        company_size: selectedTeamSize?.name,
                    })
                    .then(response => {

                        setLoading(false);
                        history.push('/onboarding-step-4/' + params.hash);

                    }).catch(error => {
                        setLoading(false);
                        console.log(error);
                        addToast({
                            type: 'error',
                            title: 'Erro ao salvar os dados!',
                            description: 'Ocorreu um erro ao tentar salvar os dados na base!',
                        });
                    });

            } else {
                addToast({
                    type: 'error',
                    title: 'Selecione uma opção!',
                    description: 'Selecione uma opção para prosseguir!',
                });
            }

        }
    }, [addToast, params.hash, onboarding, history, isPRofessional, selectedTeamSize]);

    const handleSubmit = useCallback(async (data: object[]) => {

        let companyName = ""
        if (formRef.current !== null) {
            const dataNormalized = formRef.current.getData() as unknown as formProps;
            companyName = dataNormalized.company_name_onboarding;
        }

        if (selectedTeamSize !== undefined) {
            setLoading(true);
            await api
                .post('/onboarding/', {
                    status: "3",
                    email: onboarding?.email,
                    hash: params.hash,
                    company_name: companyName,
                    company_size: selectedTeamSize?.name,
                })
                .then(response => {

                    setLoading(false);
                    history.push('/onboarding-step-4/' + params.hash);

                }).catch(error => {
                    setLoading(false);
                    console.log(error);
                    addToast({
                        type: 'error',
                        title: 'Erro ao salvar os dados!',
                        description: 'Ocorreu um erro ao tentar salvar os dados na base!',
                    });
                });

        } else {
            addToast({
                type: 'error',
                title: 'Selecione uma opção!',
                description: 'Selecione uma opção para prosseguir!',
            });
        }

    }, [addToast, params.hash, onboarding, history, selectedTeamSize]);

    useEffect(() => {

        api.get(`/onboarding`, {
            params: {
                hash: params.hash
            }
        }).then(response => {

            const apiOnboarding: Onboarding = response.data;

            setOnboarding(apiOnboarding);

            if (apiOnboarding.purpouse === "Profissional") {
                setIsProfessional(true);
            } else {
                setIsProfessional(false);
            }

            if (apiOnboarding.status === "E") { //Se já está finalizado o onboarding
                history.push('/');
                addToast({
                    type: 'error',
                    title: 'Este onboarding já foi finalizado!',
                    description: 'Este onboarding já foi encerrado, inicie um novo ou faça o login!',
                });
            }

        }).catch(error => {
            history.push('/onboarding/');
            addToast({
                type: 'error',
                title: 'Não foi encontrado o seu onboarding',
                description: 'Não foi possível localizar o seu onboarding!',
            });

        });


    }, [params.hash, addToast, history]);

    return (
        <Container>
            <title>Onboarding | Cange</title>
            <Content>
                <AnimationContainer>
                    <Header>
                        {!isMobile && (
                            <HeaderLeft>
                                <h1>Conte um pouco sobre você</h1>
                            </HeaderLeft>
                        )}
                        <HeaderRight>
                            <a href='https://www.cange.me'>
                                <img className='logo-cange' src={logo} alt="Logo wendata" />
                                <img className='logo-mini-cange' src={logo} alt="Logo wendata" />
                            </a>
                        </HeaderRight>
                    </Header>
                    <DegradeLine />
                    <Body>
                        <ContentList>
                            {isPRofessional && (
                                <FormBuilder
                                    id="formOnboardingTwo"
                                    formRef={formRef}
                                    fields={fieldsProfissionalForm}
                                    handleSubmit={handleSubmit}
                                    hideContainer={true}
                                />
                            )}

                            {isPRofessional ?
                                <h2>* Quantas pessoas trabalham na sua empresa?</h2> :
                                <h2>* Quantas pessoas vão usar o Cange com você?</h2>}
                            <ContainerGroup>
                                {teamSizeItems !== undefined ? teamSizeItems?.map((item) => (
                                    <GroupTag
                                        onClick={() => {
                                            setSelectedTeamSize(item);
                                        }}
                                        isSelected={item === selectedTeamSize}
                                        key={item.id}
                                        color={item.color}
                                    >
                                        <GroupTagContainer isSelected={item === selectedTeamSize}>
                                            <GroupTagIcon>
                                                {item !== undefined && item.icon !== undefined ?
                                                    <IconPickerItem
                                                        icon={item.icon as IconList}
                                                        color={item.color}
                                                    /> :
                                                    <IoMdListBox />
                                                }
                                            </GroupTagIcon>
                                            <GroupTagName>
                                                {item.name}
                                            </GroupTagName>
                                        </GroupTagContainer>
                                    </GroupTag>
                                )) : <></>}
                            </ContainerGroup>                
                        </ContentList>
                    </Body>
                    <Bottom>                        
                        <BottomLeft>                            
                            <ProgressBar>
                                <ProgressBarPercent percent={60} />
                            </ProgressBar>
                        </BottomLeft>
                        <BottomRight>
                            <BtnStart type="submit" onClick={handleSubmitPage} isLoading={loading}>Continuar</BtnStart>
                        </BottomRight>
                    </Bottom>
                </AnimationContainer>
            </Content>
        </Container>
    );
}

export default CompanyData;
