import React, { useCallback } from "react";

import {
    Content,
    ContentBody,
    DialogContainer,
} from "./style";

interface ExpandDialogProps {
    open: boolean;
    onClose: () => void;
    children?: React.ReactNode;
    title?: string;
    onClickConfirm?: () => void;
}

const ExpandDialog: React.FC<ExpandDialogProps> = ({
    open,
    onClose,
    children,
    title = "Expandir Editor",
    onClickConfirm,
}) => {

    const handleClose = useCallback(() => {
        onClose();
    }, [onClose]);

    return (
        <DialogContainer
            open={open}
            maxWidth="lg"
            onClose={handleClose}
        >            
            <Content>
                <ContentBody container>
                    {children}
                </ContentBody>
            </Content>            
        </DialogContainer >
    );
}

export default ExpandDialog;