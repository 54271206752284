import React, { useEffect, useState, useCallback } from "react";

import {
    Container,
    ComboContainer,
    ComboTypeField,
    OptionField,
    NumberDaysContainer,
    NumberDaysInput,
    ContainerBusinessDays,
    ItemCB,
    InputCB,
    LabelCB,
    SpanCB
} from "./style";
import { FaCalendarDay } from "react-icons/fa";
import { FieldProps } from "../../FieldBuilder";
import api from "../../../../../services/api";
import { useField } from "@unform/core";

interface SlaConfiguratorFieldProps {
    name: string;
    placeholder?: string;
    flow_id?: number;
}

export interface SlaConfiguratorProps {
    due_time_type: number;
    due_time: number;
    due_time_bd?: string;
}

const SlaConfigurator: React.FC<SlaConfiguratorFieldProps> = ({ name, placeholder, flow_id }) => {

    const { fieldName, registerField } = useField(name);
    const [dateFields, setDateFields] = useState<FieldProps[]>([]);
    const [days, setDays] = useState('');
    const [selectedField, setSelectedField] = useState<number>(-1);
    const [businessDay, setBusinessDay] = useState<string>();

    const handleChange = (value: string) => {

        setDays(value);
    };

    const getDataFieldsApi = useCallback((flowId?: number) => {

        if (flowId !== undefined) {
            api.get(`/field/by-flow`, {
                params: {
                    flow_id: flowId,
                    typeFilter: 'DUE_DATE_FIELD;DATE_PICKER_FIELD'
                }
            }).then(response => {
                if (response.data !== null) {

                    const respFields: FieldProps[] = response.data;

                    if (respFields !== undefined) {

                        setDateFields(respFields);

                    }
                }
            }).catch(error => {
                console.log(error);
            });
        }

    }, []);

    useEffect(() => {

        getDataFieldsApi(flow_id);

    }, [getDataFieldsApi, flow_id])

    useEffect(() => {
        registerField({
            name: fieldName,
            getValue: () => {
                if (days !== '') {
                    const newConfig: SlaConfiguratorProps = {
                        due_time: Number(days),
                        due_time_type: selectedField,
                        due_time_bd: businessDay
                    }

                    return newConfig;
                } else {
                    return undefined;
                }
            },
            setValue: (ref, value) => {
                if (value !== undefined) {
                    const newConfig: SlaConfiguratorProps = value;

                    setDays(String(newConfig.due_time));
                    setSelectedField(newConfig.due_time_type);
                    setBusinessDay(newConfig.due_time_bd);
                } else {
                    setDays('');
                    setSelectedField(-1);
                    setBusinessDay('0');
                }
            },
            clearValue: () => {
                setDays('');
                setSelectedField(-1);
                setBusinessDay('0');
            }
        });

    }, [fieldName, registerField, days, selectedField, dateFields, businessDay]);

    return (
        <>
            <Container>
                <ComboContainer>
                    <ComboTypeField value={selectedField} onChange={(e) => setSelectedField(Number(e.currentTarget.value))}>
                        <optgroup label="Campos Padrões">
                            <OptionField key={-1} value={-1}>Data do Movimento do Cartão</OptionField>
                            <OptionField key={-2} value={-2}>Data da Criação do Cartão</OptionField>
                        </optgroup>
                        {dateFields !== undefined && dateFields.length > 0 ?
                            <optgroup label="Campos Customizáveis">
                                {dateFields.map((f) => {
                                    return (
                                        <OptionField key={f.id_field} value={f.id_field}>{f.title}</OptionField>
                                    )
                                })}
                            </optgroup> :
                            <></>
                        }
                    </ComboTypeField>
                </ComboContainer>
                <NumberDaysContainer>
                    <FaCalendarDay />
                    <NumberDaysInput
                        type="medium"
                        placeholder={placeholder}
                        value={days}
                        onChange={(e) => handleChange(e.target.value)}
                    />
                </NumberDaysContainer>
            </Container>
            <ContainerBusinessDays>
                <ItemCB key={1}>
                    <InputCB
                        id={"opc-1"}
                        type="checkbox"
                        checked={businessDay !== undefined && businessDay === '1' ? true : false}
                        onChange={(e) => setBusinessDay(e.target.checked ? "1" : "0")}
                    />
                    <LabelCB />
                    <SpanCB htmlFor={"opc-1"}>{"Considerar somente a semana de segunda à sexta (Dias úteis)"}</SpanCB>
                </ItemCB>
            </ContainerBusinessDays>
        </>
    );

}

export default SlaConfigurator;