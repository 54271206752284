import { FieldProps } from "../../../../../components/Forms/Fields/FieldBuilder";

function sortDynamicFields(fields: FieldProps[]): FieldProps[] {
    return fields.sort((a, b) => {
        if (a.form_id !== undefined && b.form_id !== undefined) {
            if (a.form_id < b.form_id) {
                return -1;
            }
            if (a.form_id > b.form_id) {
                return 1;
            }
        }

        if (a.index < b.index) {
            return -1;
        }
        if (a.index > b.index) {
            return 1;
        }

        return 0;
    });
}

export default function getCustomFieldsFiltered(field: FieldProps, fields: FieldProps[], typeFilter?: string): FieldProps[] {

    let retFields: FieldProps[] = fields;

    //Filter the same type of field
    retFields = retFields.filter((fieldItem) => {

        if (field.variation !== undefined) {

            if (field.variation === '1') { //Text                                

                if (fieldItem.type === 'COMBO_BOX_REGISTER_FIELD') { // || fieldItem.type === 'COMBO_BOX_FLOW_FIELD') {

                    //Valid if has a field children with the same type of the field
                    const fieldChildrenType = fieldItem.field_children?.filter((fc) =>
                        fc.type === 'TEXT_SHORT_FIELD' ||
                        fc.type === 'TEXT_LONG_FIELD' ||
                        fc.type === 'MAIL_FIELD' ||
                        fc.type === 'COMBO_BOX_FIELD' ||
                        fc.type === 'RADIO_BOX_FIELD' ||
                        fc.type === 'CHECK_BOX_FIELD' ||
                        fc.type === 'DATE_PICKER_FIELD' ||
                        fc.type === 'COMBO_BOX_USER_FIELD' ||
                        fc.type === 'DUE_DATE_FIELD' ||
                        fc.type === 'CURRENCY_FIELD' ||
                        fc.type === 'NUMBER_FIELD' ||
                        fc.type === 'PHONE_FIELD' ||
                        fc.type === 'DOC_FIELD' ||
                        fc.type === 'ID_FIELD' ||
                        fc.type === 'DYNAMIC_TEXT_FIELD' ||
                        fc.type === 'LINK_FIELD'
                    );

                    return fieldChildrenType !== undefined && fieldChildrenType.length > 0;

                } else {

                    //Valid if is the same type of the field
                    return fieldItem.type === 'TEXT_SHORT_FIELD' ||
                        fieldItem.type === 'TEXT_LONG_FIELD' ||
                        fieldItem.type === 'MAIL_FIELD' ||
                        fieldItem.type === 'COMBO_BOX_FIELD' ||
                        fieldItem.type === 'RADIO_BOX_FIELD' ||
                        fieldItem.type === 'CHECK_BOX_FIELD' ||
                        fieldItem.type === 'DATE_PICKER_FIELD' ||
                        fieldItem.type === 'COMBO_BOX_USER_FIELD' ||
                        fieldItem.type === 'DUE_DATE_FIELD' ||
                        fieldItem.type === 'CURRENCY_FIELD' ||
                        fieldItem.type === 'NUMBER_FIELD' ||
                        fieldItem.type === 'PHONE_FIELD' ||
                        fieldItem.type === 'DOC_FIELD' ||
                        fieldItem.type === 'ID_FIELD' ||
                        fieldItem.type === 'DYNAMIC_TEXT_FIELD' ||
                        fieldItem.type === 'LINK_FIELD';

                }

            } else if (field.variation === '2') { //E-mail

                if (fieldItem.type === 'COMBO_BOX_REGISTER_FIELD') { // || fieldItem.type === 'COMBO_BOX_FLOW_FIELD') {

                    //Valid if has a field children with the same type of the field
                    const fieldChildrenType = fieldItem.field_children?.filter((fc) =>
                        fc.type === 'MAIL_FIELD'
                    );

                    return fieldChildrenType !== undefined && fieldChildrenType.length > 0;

                } else {

                    //Valid if is the same type of the field
                    return fieldItem.type === 'MAIL_FIELD';

                }

            } else if (field.variation === '3' || field.variation === '5') { //Rich Text

                if (fieldItem.type === 'COMBO_BOX_REGISTER_FIELD') { // || fieldItem.type === 'COMBO_BOX_FLOW_FIELD') {

                    //Valid if has a field children with the same type of the field
                    const fieldChildrenType = fieldItem.field_children?.filter((fc) =>
                        fc.type === 'TEXT_SHORT_FIELD' ||
                        fc.type === 'TEXT_LONG_FIELD' ||
                        fc.type === 'MAIL_FIELD' ||
                        fc.type === 'COMBO_BOX_FIELD' ||
                        fc.type === 'RADIO_BOX_FIELD' ||
                        fc.type === 'CHECK_BOX_FIELD' ||
                        fc.type === 'DATE_PICKER_FIELD' ||
                        fc.type === 'COMBO_BOX_USER_FIELD' ||
                        fc.type === 'DUE_DATE_FIELD' ||
                        fc.type === 'CURRENCY_FIELD' ||
                        fc.type === 'NUMBER_FIELD' ||
                        fc.type === 'PHONE_FIELD' ||
                        fc.type === 'DOC_FIELD' ||
                        fc.type === 'INPUT_RICH_TEXT_FIELD' ||
                        fc.type === 'DYNAMIC_TEXT_FIELD' ||
                        fc.type === 'ID_FIELD' ||
                        fc.type === 'LINK_FIELD'
                    );

                    return fieldChildrenType !== undefined && fieldChildrenType.length > 0;

                } else {

                    //Valid if is the same type of the field
                    return fieldItem.type === 'TEXT_SHORT_FIELD' ||
                        fieldItem.type === 'TEXT_LONG_FIELD' ||
                        fieldItem.type === 'MAIL_FIELD' ||
                        fieldItem.type === 'COMBO_BOX_FIELD' ||
                        fieldItem.type === 'RADIO_BOX_FIELD' ||
                        fieldItem.type === 'CHECK_BOX_FIELD' ||
                        fieldItem.type === 'DATE_PICKER_FIELD' ||
                        fieldItem.type === 'COMBO_BOX_USER_FIELD' ||
                        fieldItem.type === 'DUE_DATE_FIELD' ||
                        fieldItem.type === 'CURRENCY_FIELD' ||
                        fieldItem.type === 'NUMBER_FIELD' ||
                        fieldItem.type === 'PHONE_FIELD' ||
                        fieldItem.type === 'DOC_FIELD' ||
                        fieldItem.type === 'INPUT_RICH_TEXT_FIELD' ||
                        fieldItem.type === 'ID_FIELD' ||
                        fieldItem.type === 'DYNAMIC_TEXT_FIELD' ||
                        fieldItem.type === 'LINK_FIELD';

                }
            } else if (field.variation === '7') { //Rich Text - JSON

                if (fieldItem.type === 'COMBO_BOX_REGISTER_FIELD' || fieldItem.type === 'COMBO_BOX_FLOW_FIELD') {

                    //Valid if has a field children with the same type of the field
                    const fieldChildrenType = fieldItem.field_children?.filter((fc) =>
                        fc.type === 'TEXT_SHORT_FIELD' ||
                        fc.type === 'TEXT_LONG_FIELD' ||
                        fc.type === 'MAIL_FIELD' ||
                        fc.type === 'COMBO_BOX_FIELD' ||
                        fc.type === 'RADIO_BOX_FIELD' ||
                        fc.type === 'CHECK_BOX_FIELD' ||
                        fc.type === 'DATE_PICKER_FIELD' ||
                        fc.type === 'COMBO_BOX_USER_FIELD' ||
                        fc.type === 'DUE_DATE_FIELD' ||
                        fc.type === 'CURRENCY_FIELD' ||
                        fc.type === 'NUMBER_FIELD' ||
                        fc.type === 'PHONE_FIELD' ||
                        fc.type === 'DOC_FIELD' ||
                        fc.type === 'INPUT_RICH_TEXT_FIELD' ||
                        fc.type === 'ID_FIELD' ||
                        fc.type === 'LINK_FIELD' ||
                        fc.type === 'INPUT_ATTACH_FIELD'
                    );

                    return fieldChildrenType !== undefined && fieldChildrenType.length > 0;

                } else {

                    //Valid if is the same type of the field
                    return fieldItem.type === 'TEXT_SHORT_FIELD' ||
                        fieldItem.type === 'TEXT_LONG_FIELD' ||
                        fieldItem.type === 'MAIL_FIELD' ||
                        fieldItem.type === 'COMBO_BOX_FIELD' ||
                        fieldItem.type === 'RADIO_BOX_FIELD' ||
                        fieldItem.type === 'CHECK_BOX_FIELD' ||
                        fieldItem.type === 'DATE_PICKER_FIELD' ||
                        fieldItem.type === 'COMBO_BOX_USER_FIELD' ||
                        fieldItem.type === 'DUE_DATE_FIELD' ||
                        fieldItem.type === 'CURRENCY_FIELD' ||
                        fieldItem.type === 'NUMBER_FIELD' ||
                        fieldItem.type === 'PHONE_FIELD' ||
                        fieldItem.type === 'DOC_FIELD' ||
                        fieldItem.type === 'INPUT_RICH_TEXT_FIELD' ||
                        fieldItem.type === 'ID_FIELD' ||
                        fieldItem.type === 'LINK_FIELD' ||
                        fieldItem.type === 'INPUT_ATTACH_FIELD';

                }

            } else if (field.variation === '4' && typeFilter !== undefined) { //Select Type

                if (fieldItem.type === 'COMBO_BOX_REGISTER_FIELD' || fieldItem.type === 'COMBO_BOX_FLOW_FIELD') {

                    //Valid if has a field children with the same type of the field
                    const fieldChildrenType = fieldItem.field_children?.filter((fc) =>
                        fc.type === typeFilter
                    );

                    return fieldChildrenType !== undefined && fieldChildrenType.length > 0;

                } else {

                    //Valid if is the same type of the field
                    return fieldItem.type === typeFilter

                }

            }

        }

        if (fieldItem.type === 'COMBO_BOX_REGISTER_FIELD') { // || fieldItem.type === 'COMBO_BOX_FLOW_FIELD') {

            //Valid if has a field children with the same type of the field
            const fieldChildrenType = fieldItem.field_children?.filter((fc) => fc.type === field.type);

            return fieldChildrenType !== undefined && fieldChildrenType.length > 0;

        } else {

            if (fieldItem.options !== undefined && fieldItem.options.length > 0) {

                return fieldItem.type === field.type;

            } else {

                return fieldItem.type === field.type;

            }

        }

    });

    //Filter the same type of field inside the children
    retFields = retFields.map((fieldItem) => {

        if (fieldItem.type === 'COMBO_BOX_REGISTER_FIELD') { // || fieldItem.type === 'COMBO_BOX_FLOW_FIELD') {

            if (field.variation !== undefined) {
                if (field.variation === '1') { //Text   

                    const fieldChildrenType = fieldItem.field_children?.filter((fc) => fc.type === 'TEXT_SHORT_FIELD' ||
                        fc.type === 'TEXT_LONG_FIELD' ||
                        fc.type === 'MAIL_FIELD' ||
                        fc.type === 'COMBO_BOX_FIELD' ||
                        fc.type === 'RADIO_BOX_FIELD' ||
                        fc.type === 'CHECK_BOX_FIELD' ||
                        fc.type === 'DATE_PICKER_FIELD' ||
                        fc.type === 'COMBO_BOX_USER_FIELD' ||
                        fc.type === 'DUE_DATE_FIELD' ||
                        fc.type === 'CURRENCY_FIELD' ||
                        fc.type === 'NUMBER_FIELD' ||
                        fc.type === 'PHONE_FIELD' ||
                        fc.type === 'DOC_FIELD' ||
                        fc.type === 'ID_FIELD' ||
                        fc.type === 'LINK_FIELD'
                    );

                    fieldItem.field_children = fieldChildrenType;

                } else if (field.variation === '2') { //E-mail

                    const fieldChildrenType = fieldItem.field_children?.filter((fc) => fc.type === 'MAIL_FIELD');

                    fieldItem.field_children = fieldChildrenType;

                } else if (field.variation === '3' || field.variation === "5" || field.variation === '7') { //Rich Text

                    const fieldChildrenType = fieldItem.field_children?.filter((fc) => fc.type === 'TEXT_SHORT_FIELD' ||
                        fc.type === 'TEXT_SHORT_FIELD' ||
                        fc.type === 'TEXT_LONG_FIELD' ||
                        fc.type === 'MAIL_FIELD' ||
                        fc.type === 'COMBO_BOX_FIELD' ||
                        fc.type === 'RADIO_BOX_FIELD' ||
                        fc.type === 'CHECK_BOX_FIELD' ||
                        fc.type === 'DATE_PICKER_FIELD' ||
                        fc.type === 'COMBO_BOX_USER_FIELD' ||
                        fc.type === 'DUE_DATE_FIELD' ||
                        fc.type === 'CURRENCY_FIELD' ||
                        fc.type === 'NUMBER_FIELD' ||
                        fc.type === 'PHONE_FIELD' ||
                        fc.type === 'DOC_FIELD' ||
                        fc.type === 'INPUT_RICH_TEXT_FIELD' ||
                        fc.type === 'ID_FIELD' ||
                        fc.type === 'LINK_FIELD'
                    );

                    fieldItem.field_children = fieldChildrenType;

                }
            }

        }

        return fieldItem;
    });

    retFields = sortDynamicFields(retFields);

    return retFields;

}
