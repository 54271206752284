import Grid from '@material-ui/core/Grid';
import styled from "styled-components";
import { Form } from "@unform/web";

import Button from "../../../components/Button";
import FieldBoxOrg from "../../../components/FieldBox";
import FormBuilder from "../../../components/Forms/FormBuilder";

export const Container = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 15px;
`;

export const Content = styled(Grid)`
    display: flex;
    justify-content: center;
    margin-top: 15px !important;
    border-radius: 10px;
    height: auto;
    /*background-color: white;*/
`;

export const ButtonNextStep = styled(Button)`
    float: right;
    width: 130px;
`;

export const ContainerFields = styled(Grid)`
    background-color: white;
    border-radius: 10px 10px 10px 10px;
    padding-right: 4%;
    padding-left: 4%;
    padding-top: 1%;
    padding-bottom: 2%;
    display: flex;
    justify-content: center;
    height: auto;
    min-height: 83vh;
`;

export const ContainerButtonNewFormField = styled.div`
    display: flex;
    //margin-top: 15px;
    margin-bottom: 15px;
`;

export const ButtonNewFormField = styled(Button)`
    float: left;
    width: 180px;
    font-size: 14px;
`;

export const ContainerButtonSave = styled.div`
    border-top: 1px solid #8080804d;
    margin-top: 15px;
    padding-left: 25px;
    padding-right: 25px;
`;


export const ButtonSave = styled(Button)`
    margin-top: 30px !important;
`;

export const BreakArea = styled.div`
    margin-bottom: 30px !important;
`;

export const FormUnForm = styled(Form)`
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 600px;
`;

export const ContainerPreview = styled(Grid)`
    padding-left: 2%;
    padding-bottom: 0%;
    padding-right: 0;
    background-color: transparent;
`;

export const ContentPreview = styled.div`
    padding: 4%;
    border-radius: 10px;
    background-color: #e9f1ff;
`;

export const FormPreview = styled(FormBuilder)`
    
`;

export const DisclaimerPreview = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed gray;
    padding: 10px;
    margin: 15px;
    margin-bottom: 20px;

    h1 {
        margin-left: 20px;
        font-size: 16px;
        font-weight: 500;
        color: gray;
    }

    svg {
        height: 20px;
        width: 20px;
        color: gray;
    }
`;

export const Footer = styled.div`
    height: 15px;
`;

export const FieldBox = styled(FieldBoxOrg)`
    min-height: 30px !important;
    height: 30px !important;
`;

export const ContainerFieldBox = styled.div`
    margin-bottom: 15px;
`;

export const FormField = styled(FormBuilder)`
    & + div {
      margin-bottom: 15px;
      height: 100% !important;
    }
    
`;

export const ContentList = styled.div`
    display: flex;
    flex-direction: column;

    #formRefInit {
        padding: 0px;
    }
`;

export const ContainerAdrress = styled.div`
    border-radius: 8px;
    padding: 10px;
    margin-top: 25px;
    border: 1px solid #84939f;
    bottom: 20px;
    max-height: 95px;
`;

export const TitleAddress = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 18px;

    h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-left: 10px;
        font-size: 12px;
        color: #84939f;
        font-weight: 500;
        text-transform: uppercase;
    }

    svg {
        min-width: 15px;
        font-size: 15px;
        font-weight: 600;
        color: #84939f;
    }

`;

export const AddressValue = styled.div`
    padding: 10px;
    background-color: #f4f5f7;
    border-radius: 10px;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
`;

export const Address = styled.div`
    margin-right: 10px;
    font-size: 12px;
    overflow: hidden;
`;

export const CopyBtn = styled.button`
    position: absolute;
    right: 5px;
    font-weight: 500;
    padding: 3px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: #9e37ed;
    border: none;
    background-color: white;
    border-radius: 10px;

    svg {
        margin-right: 5px;
        color: #9e37ed;
    }

    :hover {
        background-color: #9e37ed;
        color: white;

        svg {
            color: white;
        }
    }
`;