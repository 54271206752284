import React from "react";

import emptyTask from '../../../assets/empty-state/empty-task.png';
import {
    Container,
    ContainerImage,
    ContainerSubTitle,
    ContainerTitle
} from "./style";

interface emptyStateProps {
    type: 'task' | 'request';
    title?: string;
    description?: string;
}

const EmptyTask: React.FC<emptyStateProps> = ({ type, title, description }) => {

    return (
        <Container>
            <ContainerImage>
                <img src={emptyTask} alt="Sem Tarefas" />
            </ContainerImage>
            <ContainerTitle>
                {type === 'task' && title === undefined ?
                    <h1>Você não possui tarefas</h1> :
                    type === 'request' && title === undefined ?
                        <h1>Você ainda não possui solicitações</h1> :
                        <></>
                }

                {title !== undefined && (
                    <h1>{title}</h1>
                )}
            </ContainerTitle>
            <ContainerSubTitle>
                {type === 'task' && description === undefined ?
                    <span>Por enquanto você não tem nenhuma tarefa para realizar</span> :
                    type === 'request' && description === undefined ?
                        <span>Crie sua primeira solicitação em um fluxo para acompanhar por aqui</span> :
                        <></>
                }

                {description !== undefined && (
                    <span>{description}</span>
                )}
            </ContainerSubTitle>
        </Container>
    );

}

export default EmptyTask;