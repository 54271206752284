import React, { useCallback, useEffect, useState } from "react";

import {
    Container,
    DynamicTextContainer
} from "./style";
import { Card } from "../../interfaces/Card";
import getDynamicField from "../../utils/getDynamicField";

interface DynamicContentPreviewProps {
    formula?: string;
    card_current?: Card;
}
const DynamicContentPreview: React.FC<DynamicContentPreviewProps> = ({ formula, card_current }) => {

    const [valueFormula, setValueFormula] = useState<string>("");

    const handleParseFormula = useCallback(async () => {

        if (formula !== undefined && formula !== null && formula !== '' && card_current !== undefined) {

            let dynamicText = formula;

            //Create a regex to find the dynamic fields
            const regexOrigin = /<span[^>]*class="field-dynamic-tag"[^>]*>.*?<\/span>/g;
            const regexFieldId = /field-id="(\d+(?:->\d+)?)"/;

            //Match the regex and get the position start and end of the match
            let matchesToRplace = dynamicText.match(regexOrigin);

            //Iterate over the matches
            if (matchesToRplace !== null && matchesToRplace.length > 0) {
                for (let index = 0; index < matchesToRplace.length; index++) {
                    const match = matchesToRplace[index];

                    //Find the field-id
                    const fieldId = match.match(regexFieldId);

                    if (fieldId !== null && fieldId.length > 1) {
                        const value = await getDynamicField('card', card_current, fieldId[1]);
                        dynamicText = dynamicText.replace(match, value);
                    }
                }
            }

            setValueFormula(dynamicText);

        }

    }, [formula, card_current]);


    useEffect(() => {

        handleParseFormula();

    }, [handleParseFormula]);

    return (
        <Container>

            {card_current === undefined ?
                <DynamicTextContainer dangerouslySetInnerHTML={{ __html: formula !== undefined ? formula : "" }} /> :
                <DynamicTextContainer dangerouslySetInnerHTML={{ __html: valueFormula !== undefined ? valueFormula : "" }} />
            }

        </Container>
    );

}

export default DynamicContentPreview;