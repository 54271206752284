import React, { useCallback, useState } from "react";

import {
    DialogContainer,
} from "./style";

import { Document, Page, pdfjs } from 'react-pdf';
import type { PDFDocumentProxy } from 'pdfjs-dist';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const maxWidth = 800;

interface PdfViewerProps {
    open: boolean;
    file: { url: string, fileType: string, name: string, base64?: string };
    onClose: () => void;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ onClose, open, file }) => {

    const [numPages, setNumPages] = useState<number>();
    const [containerWidth] = useState<number>();

    const handleClose = useCallback(async () => {
        onClose();
    }, [onClose]);

    function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
        setNumPages(nextNumPages);
    }

    return (
        <DialogContainer
            fullWidth={true}
            maxWidth="md"
            open={open}
            onClose={handleClose}
            style={{ zIndex: 999999999999999 }}
        >
            <Document file={`data:application/pdf;base64,${file.base64}`} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                {Array.from(new Array(numPages), (_el, index) => (
                    <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        canvasBackground="white"
                        renderTextLayer={false}
                        renderForms={false}
                        renderAnnotationLayer={false}
                        width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                    />
                ))}
            </Document>
        </DialogContainer >
    );

}

export default PdfViewer;