import { AxiosResponse } from "axios";
import { Card } from "../interfaces/Card";
import { FormAnswer } from "../interfaces/FormAnswer";
import api from "../services/api";

//IF CHANGE HERE...
//Need to change in Front End + Back End too!

interface UrlFile {
    url: string;
    mime_type?: string;
    base64?: string;
}

interface UrlFileRet {
    url: string;
    mime_type?: string;
}

async function getFileUrl(id_attachment: string, isPublicForm?: boolean, public_hash?: string): Promise<UrlFileRet> {
    try {

        //logic to get public
        const endpoint = isPublicForm ? `/form-public/attachment/url-download` : '/attachment/url-download';

        const response: AxiosResponse<UrlFile> = await api.get(endpoint, {
            params: {
                id_attachment,
                withBase64: false,
                public_hash
            },
        });

        const { url, mime_type } = response.data;

        if (!url) {
            throw new Error('URL não encontrada na resposta da API');
        }

        return { url, mime_type };
    } catch (error) {
        console.error('Erro ao obter a URL do arquivo:', error);
        throw error;
    }
}

const getViewerByFileType = (fileExtension: string) => {
    console.log(fileExtension)
    switch (fileExtension) {
        case "image/jpg":
        case "image/jpeg":
        case "image/png":
        case "image/gif":
            return 'imageRender';
        default:
            return 'buttonDownload';
    }
};

export default async function getDynamicField(
    type_object_reference: string,
    objectReference: Card | FormAnswer[],
    field_id: string,
    isPublicForm?: boolean,
    public_hash?: string
): Promise<string> {

    let retValue = "";
    let hasChieldField = false;

    let fieldId: number;
    let fieldChieldId: number;

    //Valid if has a chield field
    if (field_id.includes('->')) {

        hasChieldField = true;

        const fieldParts = field_id.split('->');

        if (fieldParts.length === 2) {

            const firstPart = fieldParts[0];
            const secondPart = fieldParts[1];

            if (!isNaN(Number(firstPart))) {
                fieldId = Number(firstPart);
            }

            if (!isNaN(Number(secondPart))) {
                fieldChieldId = Number(secondPart);
            }

        }
    } else {

        if (!isNaN(Number(field_id))) {
            fieldId = Number(field_id);
        }

    }

    if (objectReference !== undefined && objectReference !== null) {
        if (type_object_reference === 'card') {

            const card = objectReference as Card;

            //Get field and valid if is from register TO BE CODED...

            //Get the columns
            if (card.form_answers !== undefined && card.form_answers.length > 0) {

                //Get all from answers that have the field
                let formAnswers = card.form_answers?.filter(formAnswer => {
                    const findField = formAnswer.form_answer_fields.filter(faf => faf.field_id === fieldId);
                    return findField.length > 0;
                });

                //Order formAnswers from the highest to the lowest id_form_answer
                formAnswers = formAnswers.sort((a, b) => b.id_form_answer - a.id_form_answer);

                if (formAnswers !== undefined && formAnswers.length > 0 && formAnswers[0].form_answer_fields !== undefined && formAnswers[0].form_answer_fields.length > 0) {

                    if (!hasChieldField) { //Get directly from the form_answer_fields

                        const typeField = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === fieldId)[0].field.type;

                        if (typeField !== undefined && typeField === "INPUT_ATTACH_FIELD") {

                            //Get the value from the formAnswer                        
                            retValue = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === fieldId)[0].value;

                            if (retValue !== undefined && retValue !== null && retValue !== "") {
                                try {
                                    const { url, mime_type } = await getFileUrl(retValue, isPublicForm, public_hash);

                                    console.log(url, mime_type);
                                    if (mime_type !== undefined && mime_type !== null) {

                                        if (getViewerByFileType(mime_type) === 'imageRender') {
                                            retValue = `<img src="${url}" alt="Imagem" style="max-width: 100%; max-height: 100%;" />`;
                                        } else {
                                            retValue = `<a class="btn-public-file" href="${url}" target="_blank" rel="noreferrer">Baixar anexo</a>`;
                                        }


                                    } else {

                                        retValue = retValue = `<a class="btn-public-file" href="${url}" target="_blank" rel="noreferrer">Baixar anexo</a>`;
                                    }

                                    //`<a class="btn-public-step" href="${url}" target="_blank" rel="noreferrer">Clique aqui para acessar</a>`; 
                                } catch (error) {
                                    retValue = "{Erro ao obter o arquivo}";
                                }
                            }

                        } else {

                            //Get the value from the formAnswer                        
                            retValue = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === fieldId)[0].valueString;

                        }

                    } else { //Get from the child field

                        const formAnswerField = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === fieldId)[0];

                        if (formAnswerField !== undefined && formAnswerField !== null) {

                            //Check if has valueRegister
                            if (formAnswerField.valueRegister !== undefined && formAnswerField.valueRegister !== null) {

                                //Check if has the form_answer_fields
                                if (formAnswerField.valueRegister.form_answer_fields !== undefined && formAnswerField.valueRegister.form_answer_fields !== null && formAnswerField.valueRegister.form_answer_fields.length > 0) {

                                    //Get the value from the formAnswer
                                    retValue = formAnswerField.valueRegister.form_answer_fields.filter(faf => faf.field_id === fieldChieldId)[0].valueString;

                                }

                            }

                        }

                    }

                }

            }

        } else if (type_object_reference === 'form_answer') {

            let formAnswers = objectReference as FormAnswer[];

            //Order formAnswers from the highest to the lowest id_form_answer
            formAnswers = formAnswers.sort((a, b) => b.id_form_answer - a.id_form_answer);

            if (formAnswers !== undefined && formAnswers.length > 0 && formAnswers[0].form_answer_fields !== undefined && formAnswers[0].form_answer_fields.length > 0) {

                if (!hasChieldField) { //Get directly from the form_answer_fields

                    //Get the value from the formAnswer                        
                    retValue = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === fieldId)[0].valueString;

                } else { //Get from the chield field

                    const formAnswerField = formAnswers[0].form_answer_fields.filter(faf => faf.field_id === fieldId)[0];

                    if (formAnswerField !== undefined && formAnswerField !== null) {

                        //Check if has valueRegister
                        if (formAnswerField.valueRegister !== undefined && formAnswerField.valueRegister !== null) {

                            //Check if has the form_answer_fields
                            if (formAnswerField.valueRegister.form_answer_fields !== undefined && formAnswerField.valueRegister.form_answer_fields !== null && formAnswerField.valueRegister.form_answer_fields.length > 0) {

                                //Get the value from the formAnswer
                                retValue = formAnswerField.valueRegister.form_answer_fields.filter(faf => faf.field_id === fieldChieldId)[0].valueString;

                            }

                        }

                    }

                }

            }


        }
    }

    return retValue === undefined || retValue === null || retValue === "" ? "{Sem registro}" : retValue;

}