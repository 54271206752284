import React from "react";

import {
    Container
} from "./style";
import Avatar from "react-avatar";
import { User } from "../../interfaces/User";

interface AvatarCangeProps {
    user?: User;
    size?: string;
}

const AvatarCange: React.FC<AvatarCangeProps> = ({ user, size = "45" }) => {

    return (
        <Container>

            {user !== undefined ?
                <Avatar src={user?.avatar_url} color={user?.color} size={size} name={user?.name} round="100%" /> :
                <Avatar color={"#9e37ee"} size={size} name={"..."} round="100%" />
            }

        </Container>
    );

}

export default AvatarCange;