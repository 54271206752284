import {
    AggregateConfig,
    AggregateItemLeft,
    AggregateItemRight,
    AggregationContainer,
    AggregationItem,
    AggregationName,
    AggregationNameText,
    AggregationNameType,
    AggregationValue,
    AutomaticData,
    ButtonConfig,
    ButtonNewItem,
    ButtonNewItemMobile,
    ButtonUserAdd,
    ContainerBody,
    ContainerContent,
    DataTableCange,
    DropDownInfo,
    LoaderContainer,
    NoRegister,
    RegisterData,
    RightPanelItems,
    RightPanelList
} from "./styles";
import { BsFiletypeCsv, BsFillGearFill, BsFillPeopleFill, BsGearFill, BsPrinter } from "react-icons/bs";
import { FaLock, FaLockOpen, FaPlus, FaTable } from "react-icons/fa";
import { FieldProps, getMaskField } from "../../components/Forms/Fields/FieldBuilder";
import { IoMdListBox, IoMdPersonAdd, IoMdSwitch } from "react-icons/io";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useHistory, useRouteMatch } from 'react-router-dom';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

import ConfigCard from "../../dialogs/ConfigCard";
import DialogAction from "../../dialogs/DialogAction";
import DataList from '../../components/DataList';
import EmptyCard from "../../components/EmptyState/EmptyCard";
import { FiTrash2 } from "react-icons/fi";
import { FormAnswer } from '../../interfaces/FormAnswer';
import { FormAnswerField } from '../../interfaces/FormAnswerField';
import { IDataTableColumn } from 'react-data-table-component';
import { IconList } from "react-fa-icon-picker";
import InviteUser from "../../dialogs/InviteUser";
import LayoutContainer from '../../components/LayoutContainer';
import { Link } from "react-router-dom";
import ListContainer from '../../components/ListContainer';
import NewAnswer from "../../dialogs/NewAnswer";
import Record from "../../dialogs/Record";
import { Register } from "../../interfaces/Register";
import RightPanel from "../../components/RightPanel";
import api from '../../services/api';
import getAccessControl from "../../middlewares/AccessControl";
import updateFormulaFromFormAnswer from "../../utils/Formula/updateFormulaFromFormAnswer";
import { useToast } from '../../hooks/toast';
import { TbDatabaseImport } from "react-icons/tb";
import ImporterData from "../../dialogs/ImporterData";
import FilterBarRegister from "../../components/FilterBarRegister";
import { FieldViewProps, FilterRegisterProps } from "../../components/FilterBarRegister/FilterDialogRegister";
import AppBarHeader, { NavigationBarItemType } from "../../components/AppBarHeader";
import DropDownItem from "../../components/DropDownList/DropDownItem";
import DropDownDivider from "../../components/DropDownList/DropDownDivider";
import { formatDateToDateRead } from "../../utils/formatDateToDateRead";
import { useReactToPrint } from "react-to-print";
import getFormAnswerFiltered from "../../middlewares/getFormAnswerFiltered";
import getFormAnswerSorted from "../../middlewares/getFormAnswerSorted";
import getFormAnswerAggregations from "../../middlewares/getFormAnswerAggregations";
import ContentLoader from "react-content-loader";
import { FlowConfigGroup, FlowConfigItem, FlowConfigItemDescription, FlowConfigItemIcon, FlowConfigItems, FlowConfigTitle } from "../../components/RightPanel/styles";
import CheckListView from "../../components/Forms/Fields/Components/CheckListField/CheckListView";
import { getFeatureControlByPlan } from "../../middlewares/FeatureControl";
import { useAuth } from "../../hooks/auth";
import IconColorPicker from "../../components/IconColorPicker";
import Attachments from "../../components/Colaboration/Attachments";

interface RouteParams {
    hash: string;
    origin?: string;
    id?: string;
}

const customStyles = {
    headRow: {
        style: {
            borderTop: 'solid 0.5px #e0e0e0'
        },
    },
    headCells: {
        style: {
            color: 'black',
            fontSize: '14px',
            //fontWeight: 400,
            borderBottom: 'solid 0.5px #e0e0e0',
            borderRight: 'solid 0.5px #e0e0e0',
            borderLeft: 'solid 0.5px #e0e0e0',

        },
    },
    rows: {
        highlightOnHoverStyle: {
            backgroundColor: '#f4f4f4',
            borderBottomColor: '#FFFFFF',
            outline: '1px solid white',
        },
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
    cells: {
        style: {
            borderRight: 'solid 0.5px #e0e0e0',
            borderLeft: 'solid 0.5px #e0e0e0'
        }
    },
};

const navBarItemsDefault: NavigationBarItemType[] = [
    {
        index: 0,
        description: "Cadastro",
        icon: FaTable,
        iconSize: '13.5',
        active: true,
        onClick: () => { }
    }
]

interface ColumnSizeProps {
    id_field: number;
    width: number;
}

interface AggregateItemsProps {
    columnName: string;
    typeField: string;
    typeAggregate: string;
    variation?: string;
    value: number;
}

const LoaderTable = () => (
    <ContentLoader
        speed={2}
        height={500}
        width={'100%'}
        backgroundColor="#ffffff"
        foregroundColor="#f4f5f7"
    >
        <rect x="0" y="0" rx="0" ry="0" width="350px" height="55px" />
        <rect x="355" y="0" rx="0" ry="0" width="200px" height="55px" />
        <rect x="560" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="745" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="930" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="1115" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="1300" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="1485" y="0" rx="0" ry="0" width="180px" height="55px" />
        <rect x="1670" y="0" rx="0" ry="0" width="4000px" height="55px" />

        <rect x="0" y="60" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="115" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="170" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="225" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="280" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="335" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="390" rx="0" ry="0" width="4000px" height="50px" />
        <rect x="0" y="445" rx="0" ry="0" width="4000px" height="50px" />
    </ContentLoader>
)


const RegisterPage: React.FC = () => {

    const { params } = useRouteMatch<RouteParams>();
    const { addToast } = useToast();
    const { user } = useAuth();
    const [register, setRegister] = useState<Register>();
    const [selectedRecord, setSelectedRecord] = useState<FormAnswer>();
    const history = useHistory();

    const [openOnLoad, setOpenOnLoad] = useState(false);
    const [openNewAnswer, setOpenNewAnswer] = useState(false);
    const [openRightPanel, setOpenRightPanel] = useState(false);
    const [openInviteUser, setOpenInviteUser] = useState(false);
    const [openImporterData, setOpenImporterData] = useState(false);
    const [stateDelRegister, setStateDelRegister] = useState(false);
    const [openRecord, setOpenRecord] = useState(false);
    const [openConfigCard, setOpenConfigCard] = useState(false);
    const [columnConfig, setColumnConfig] = useState<IDataTableColumn[]>();
    const [aggregateItems, setAggregateItems] = useState<AggregateItemsProps[]>([]);
    const [fixedHeaderHeight, setFixedHeaderHeight] = useState("450px");

    const [subHeaderAlign] = React.useState('right');
    const [noHeader] = React.useState(true);

    const [loadingDelRegister, setLoadingDelRegister] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);

    const [itemNavBarSelected] = useState<number>(0);
    const [filterRegister, setFilterRegister] = useState<FilterRegisterProps>();
    const [initFilterRegister] = useState<FilterRegisterProps>();
    const [navBarItems] = useState(navBarItemsDefault);
    const [formAnswers, setFormAnswers] = useState<FormAnswer[]>([]);
    const [formAnswersFiltered, setFormAnswersFiltered] = useState<FormAnswer[]>([]);
    const printComponentRef = useRef(null);

    const [nameRegister, setNameRegister] = useState<string>("");
    const [colorRegister, setColorRegister] = useState<string>("");
    const [iconRegister, setIconRegister] = useState<string>("");

    const [openIconColorPicker, setOpenIconColorPicker] = useState<boolean>(false);

    const getColumnsConfig = useCallback((columnsSize?: ColumnSizeProps[], fieldView?: FieldViewProps[]) => {

        let newColumnConfig: IDataTableColumn[] = [];

        if (register !== undefined) {

            let isControledByFieldView = false;
            if (fieldView !== undefined && fieldView.length > 0) {
                isControledByFieldView = true;
            }

            //Fields Default
            const idColCreated = -1;
            const colDtCreated: IDataTableColumn = {
                id: idColCreated,
                name: "Criado em",
                cell: (row) => {
                    return (
                        <AutomaticData>{row.dt_created_string}</AutomaticData>
                    )
                },
                minWidth: "200px",
                center: true
            }
            if (isControledByFieldView && fieldView !== undefined && fieldView.length > 0) {
                const idx = fieldView.findIndex((fv) => fv.id_field === idColCreated);
                if (idx !== undefined && fieldView[idx] !== undefined) {
                    if (fieldView[idx].active) {
                        newColumnConfig.push(colDtCreated);
                    }
                }
            } else {
                newColumnConfig.push(colDtCreated);
            }

            const idColCreatedBy = -2;
            const colCreatedBy: IDataTableColumn = {
                id: idColCreatedBy,
                name: "Criado por",
                cell: (row) => {
                    return (
                        <AutomaticData>{row.user_created}</AutomaticData>
                    )
                },
                minWidth: "200px",
                center: true
            }
            if (isControledByFieldView && fieldView !== undefined && fieldView.length > 0) {
                const idx = fieldView.findIndex((fv) => fv.id_field === idColCreatedBy);
                if (idx !== undefined && fieldView[idx] !== undefined) {
                    if (fieldView[idx].active) {
                        newColumnConfig.push(colCreatedBy);
                    }
                }
            } else {
                newColumnConfig.push(colCreatedBy);
            }

            const idColIdentify = -3;
            const colIdentify: IDataTableColumn = {
                id: idColIdentify,
                name: "Identificador",
                cell: (row) => {
                    return (
                        <AutomaticData>#{row.id_form_answer}</AutomaticData>
                    )
                },
                minWidth: "50px",
                center: true
            }
            if (isControledByFieldView && fieldView !== undefined && fieldView.length > 0) {
                const idx = fieldView.findIndex((fv) => fv.id_field === idColIdentify);
                if (idx !== undefined && fieldView[idx] !== undefined) {
                    if (fieldView[idx].active) {
                        newColumnConfig.push(colIdentify);
                    }
                }
            } else {
                newColumnConfig.push(colIdentify);
            }

            if (isControledByFieldView && fieldView !== undefined && fieldView.length > 0) {

                const fieldViewActive = fieldView.filter((fv) => fv.active);

                if (fieldViewActive !== undefined && fieldViewActive.length > 0) {
                    for (let index = 0; index < fieldViewActive.length; index++) {
                        const fv = fieldViewActive[index];

                        //Find the field object
                        let fieldObj: FieldProps | undefined = register.form?.fields.find((field) => field.id_field === fv.id_field);

                        if (fieldObj !== undefined && fieldObj.id_field !== undefined) {
                            const columnWidth = columnsSize?.filter((col) => col.id_field === fv.id_field);

                            const column: IDataTableColumn = {
                                id: fv.id_field,
                                name: fv.title.toString(),
                                cell: (row) => {

                                    if (fieldObj !== undefined) {
                                        const value: FormAnswerField[] = row.form_answer_fields.filter((answer: FormAnswerField) => fieldObj !== undefined && answer.field_id === fieldObj.id_field);
                                        const isMultiple = fieldObj.type === "COMBO_BOX_REGISTER_FIELD" || fieldObj.type === "COMBO_BOX_FLOW_FIELD" || fieldObj.type === "INPUT_LIST_FIELD" || fieldObj.type === "CHECK_BOX_FIELD" || fieldObj.type === "COMBO_BOX_FIELD" ? true : false;

                                        return (
                                            value === undefined || value.length <= 0 || fieldObj === undefined ?
                                                <NoRegister>Sem registro</NoRegister> :

                                                fieldObj.type === "CHECK_LIST_FIELD" ?
                                                    <CheckListView value={value} onlyPercent /> :

                                                    value.map((val, index) => {
                                                        return (
                                                            isMultiple && fieldObj !== undefined && fieldObj.register !== undefined && val.relatedOrigin !== undefined && val.relatedOrigin === 'register' ?
                                                                <RegisterData key={index} color={fieldObj.register.color}>{val.valueString}</RegisterData> :
                                                                isMultiple && fieldObj !== undefined && fieldObj.flow !== undefined && val.relatedOrigin !== undefined && val.relatedOrigin === 'flow' ?
                                                                    <RegisterData key={index} color={fieldObj.flow?.color}>{val.valueString}</RegisterData> :
                                                                    isMultiple ?
                                                                        <RegisterData key={index}>{val.valueString}</RegisterData> :
                                                                        fieldObj !== undefined && fieldObj.type === "INPUT_ATTACH_FIELD" && val.valueAttachment !== undefined ?
                                                                            <Attachments
                                                                                key={val.valueAttachment?.id_attachment}
                                                                                uploadedFiles={[val.valueAttachment]}
                                                                                maxLength={"1"} typeUser={"V"}
                                                                                variation={fieldObj.variation}
                                                                                card_current_id={row.id_card}
                                                                                flow_id={row.flow_id}
                                                                                widget='highlight'
                                                                            /> :
                                                                            <div key={val.id_form_answer_field + val.index}>{val.valueString}</div>
                                                        )
                                                    })
                                        )
                                    } else {
                                        return <></>
                                    }

                                },
                                minWidth: columnWidth !== undefined && columnWidth.length > 0 ? columnWidth[0].width + "px" : "175px",
                                allowOverflow: false
                            }

                            newColumnConfig.push(column);

                        }

                    }
                }

                //Order the array
                newColumnConfig = newColumnConfig.sort((a: IDataTableColumn, b: IDataTableColumn) => {

                    const findPositionA = fieldView.find((f) => f.id_field === a.id);
                    const findPositionB = fieldView.find((f) => f.id_field === b.id);

                    if (findPositionA !== undefined && findPositionB !== undefined) {
                        return findPositionA.index - findPositionB.index;
                    }

                    if (findPositionA === undefined) {
                        return 1;
                    }

                    if (findPositionB === undefined) {
                        return -1;
                    }

                    return 0;
                });
            }
        }

        return newColumnConfig;

    }, [register]);

    const handleColumnsSize = useCallback(() => {

        //Get the size of columns
        let columnsSize: ColumnSizeProps[] = [];
        let sizeByPixel = 8; //7.13

        if (filterRegister !== undefined && filterRegister.fieldView !== undefined && filterRegister.fieldView.length > 0) {

            if (register !== undefined && register.form_answers !== undefined && register.form_answers !== null && register.form_answers.length > 0) {

                for (let indexField = 0; indexField < filterRegister.fieldView.length; indexField++) {
                    const fv = filterRegister.fieldView[indexField];

                    const plusSpace: number = fv.type === "COMBO_BOX_REGISTER_FIELD" || fv.type === "COMBO_BOX_FLOW_FIELD" ? 60 : 0;
                    const maxSize = 400;
                    const minSize = 175;
                    let countFields = 1;

                    const maxValueStringLength = register.form_answers.reduce((max, formAnswer) => {
                        if (formAnswer !== undefined) {

                            const faf = formAnswer.form_answer_fields.filter((faf) => faf.field_id === fv.id_field);

                            countFields = faf.length >= countFields ? faf.length : countFields;

                            if (faf.length >= 2) {
                                countFields = 2;
                            }

                            if (faf !== undefined && faf.length > 0) {
                                const valueStringLength = faf[0] !== undefined && faf[0].valueString !== undefined ? faf[0].valueString.length : 0;

                                return valueStringLength > max ? valueStringLength : max;

                            }
                        }
                        return max;
                    }, 0);

                    let sizeColumn = maxValueStringLength !== undefined ? maxValueStringLength * sizeByPixel : 100;
                    sizeColumn = sizeColumn < minSize ? minSize : sizeColumn > maxSize ? maxSize : sizeColumn;

                    let finalWidth = (sizeColumn * countFields) + (plusSpace * countFields);

                    //Default Value
                    if (fv.type === "CHECK_LIST_FIELD") {
                        finalWidth = 300;
                    } else if (fv.type === "INPUT_ATTACH_FIELD") {
                        finalWidth = 340 * countFields;
                    }

                    columnsSize.push({
                        id_field: fv.id_field,
                        width: finalWidth
                    } as ColumnSizeProps);

                }

            }

        }

        if (columnsSize !== undefined && columnsSize.length > 0) {
            const columnConfig = getColumnsConfig(columnsSize, filterRegister?.fieldView);
            return columnConfig;
        }

        return undefined;

    }, [register, filterRegister, getColumnsConfig]);

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current
    });

    const configCardOpen = useCallback(async () => {
        if (openRightPanel) {
            setOpenRightPanel(false);
        }
        setOpenConfigCard(true);
    }, [openRightPanel]);

    const configCardClose = useCallback(async () => {
        setOpenConfigCard(false);
    }, []);

    const dialogNewAnswerSubmmit = useCallback(async () => {

    }, []);

    const dialogNewAnswerClose = useCallback(async () => {
        setOpenNewAnswer(false)
    }, []);

    const dialogDelRegisterSubmmit = useCallback(async () => {

        if (register !== undefined) {
            setLoadingDelRegister(true);
            await api
                .delete('/register?id_register=' + register?.id_register)
                .then(response => {

                    setStateDelRegister(false);

                    history.push('/register-list');
                    setLoadingDelRegister(false);
                }).catch(error => {
                    setLoadingDelRegister(false);
                    const errMessage: string = error.response.data.message;

                    addToast({
                        type: 'error',
                        title: 'Erro ao deletar o Cadastro',
                        description: errMessage,
                    });
                });

        } else {

            addToast({
                type: 'error',
                title: 'Erro ao deletar o Cadastro',
                description: 'Ocorreu um erro ao deletar o Cadastro!',
            });

        }

    }, [addToast, history, register]);

    const getAggregations = useCallback(async (formAnswers: FormAnswer[]) => {

        if (register !== undefined && register.fields_aggregate !== undefined && register.fields_aggregate.length >= 0) {
            const newAggregation = getFormAnswerAggregations(register.fields_aggregate, formAnswers);
            setAggregateItems(newAggregation);
        }

    }, [register]);

    const getApiRegister = useCallback(async () => {
        api.get(`/register/`, {
            params: {
                hash: params.hash,
                withAnswers: true
            }
        }).then(response => {
            if (response.data !== null) {

                let respRegister: Register = response.data;

                if (respRegister !== undefined) {

                    //Update and mask formula fields
                    if (respRegister.form_answers !== undefined && respRegister.form !== undefined) {

                        const fields: FieldProps[] = respRegister.form?.fields;

                        respRegister.form_answers = respRegister.form_answers?.map((fa) => {
                            return updateFormulaFromFormAnswer(fa, fields);
                        })
                    }

                    setRegister(respRegister);

                    //Update the name of the flow
                    if (respRegister.name !== undefined && respRegister.name !== null) {
                        setNameRegister(respRegister.name);
                    }
                    //Update the color of the flow
                    if (respRegister.color !== undefined && respRegister.color !== null) {
                        setColorRegister(respRegister.color);
                    }
                    //Update the icon of the flow
                    if (respRegister.icon !== undefined && respRegister.icon !== null) {
                        setIconRegister(respRegister.icon);
                    }

                    if (respRegister.form !== undefined && respRegister.form_answers !== undefined) {
                        //const newColumnConfig = getColumnsConfig(respRegister.form, respRegister.form_answers);
                        //setColumnConfig(newColumnConfig);

                        setFormAnswers(respRegister.form_answers);
                    }

                    if (params.origin === "register" && params.id !== undefined) {

                        //Open a register record by url
                        const answerSelected = respRegister.form_answers?.filter((answer) => String(answer.id_form_answer) === params.id);
                        if (answerSelected !== undefined && answerSelected.length > 0 && !openOnLoad) {
                            setOpenRecord(true);
                            setSelectedRecord(answerSelected[0]);
                            setOpenOnLoad(true);
                        }

                    }

                    //Set the filter flow                

                }
            }
            setLoading(false);
        }).catch(error => {
            setLoading(false);
            history.push('/register-list');
            addToast({
                type: 'error',
                title: 'Erro ao abrir o Cadastro',
                description: 'Ocorreu um problema tentar abrir o Cadastro ou você não possui acesso!',
            });
        });

    }, [addToast, params.hash, history, params.id, params.origin, openOnLoad]);

    const onRowClicked = useCallback(async (row: FormAnswer) => {
        setOpenRecord(true);
        setSelectedRecord(row);
    }, []);

    const onFilterRegister = useCallback(async (filterRegister: FilterRegisterProps, openedDialog: boolean) => {

        if (openedDialog === false) {
            if (filterRegister?.searchText !== undefined && filterRegister?.searchText !== "") {
                setFilterRegister({ fieldView: filterRegister.fieldView, conditions: filterRegister.conditions, orderBy: filterRegister.orderBy, searchText: filterRegister?.searchText });
            } else {
                setFilterRegister({ fieldView: filterRegister.fieldView, conditions: filterRegister.conditions, orderBy: filterRegister.orderBy });
            }

            //Save the filter flow (For All)
            //Create the fields on register...

        }

    }, []);

    const downloadCSVData = useCallback(async () => {

        try {

            if (register !== undefined) {

                const formAnswers = formAnswersFiltered;
                //Create the object to export
                let obj: object[] = [];

                for (let index = 0; index < formAnswers.length; index++) {
                    const fa = formAnswers[index];

                    let objCard: any = {
                        id_form_answer: fa.id_form_answer,
                        dt_created: fa.dt_created,
                        user_created: fa.user_created,
                    }

                    if (filterRegister !== undefined && filterRegister.fieldView !== undefined && filterRegister.fieldView.length > 0) {
                        for (let idxB = 0; idxB < filterRegister.fieldView.length; idxB++) {
                            const fv = filterRegister.fieldView[idxB];

                            let fah = fa.form_answer_fields.filter((faf) => faf.field_id === fv.id_field);

                            if (fah !== undefined && fah.length > 0) {
                                if (fah.length === 1) {
                                    if (fah[0].valueString !== undefined) {
                                        objCard[fv.title] = fah[0].valueString;
                                    }
                                } else {

                                    objCard[fv.title] = "";

                                    for (let idxC = 0; idxC < fah.length; idxC++) {
                                        const faf = fah[idxC];

                                        objCard[fv.title] += faf.valueString + ";";
                                    }
                                }
                            } else {
                                objCard[fv.title] = "";
                            }

                        }
                    }

                    obj.push(objCard);
                }

                const csv = Papa.unparse(obj);
                const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
                saveAs(blob, register.name + '.csv');

            }


        } catch (error) {
            addToast({
                type: 'error',
                title: 'Erro ao carregar os dados do Fluxo',
                description: 'Ocorreu ao tentar carregar os dados do Fluxo!',
            });
        }
    }, [addToast, register, filterRegister, formAnswersFiltered]);

    const handleSaveNameRegister = useCallback(async () => {

        if (register !== undefined && nameRegister !== undefined && nameRegister !== "" && nameRegister !== register.name) {

            await api.post('/register', {
                hash: register.hash,
                name: nameRegister
            }).then(response => {
                if (response.data !== null) {
                    addToast({
                        type: 'success',
                        title: 'Nome do Cadastro salvo!',
                        description: 'O nome do Cadastro foi salvo com sucesso!',
                    });
                }
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar o nome do Cadastro',
                    description: 'Ocorreu um erro ao salvar o nome do Cadastro!',
                });
            });

        }

    }, [addToast, register, nameRegister]);

    const handleSaveColorIcon = useCallback(async () => {

        if (register !== undefined && colorRegister !== undefined && iconRegister !== undefined && colorRegister !== "" && iconRegister !== "" && (colorRegister !== register.color || iconRegister !== register.icon)) {

            await api.post('/register', {
                hash: register.hash,
                color: colorRegister,
                icon: iconRegister
            }).then(response => {
                if (response.data !== null) {
                    addToast({
                        type: 'success',
                        title: 'Cor e ícone do Cadastro salvos!',
                        description: 'A cor e o ícone do Cadastro foram salvos com sucesso!',
                    });
                }
            }).catch(error => {
                addToast({
                    type: 'error',
                    title: 'Erro ao salvar a cor e o ícone do Cadastro',
                    description: 'Ocorreu um erro ao salvar a cor e o ícone do Cadastro!',
                });
            });

        }

    }, [addToast, register, colorRegister, iconRegister]);

    const handleOpenIconColorPicker = useCallback(async () => {

        setOpenIconColorPicker(true);

    }, []);

    const handleCloseIconColorPicker = useCallback(() => {

        setOpenIconColorPicker(false);

        handleSaveColorIcon();

    }, [handleSaveColorIcon]);

    useEffect(() => {

        if (!openNewAnswer && !openRecord && !openConfigCard && !openImporterData) {
            getApiRegister();
        }

    }, [getApiRegister, openNewAnswer, openRecord, openConfigCard, openImporterData]);

    useEffect(() => {

        if (!openRecord) {
            setSelectedRecord(undefined);
        }

    }, [openRecord]);

    useEffect(() => {

        let newFormAnswers: FormAnswer[] | undefined = formAnswers;

        if (newFormAnswers !== undefined && filterRegister !== undefined) {

            newFormAnswers = getFormAnswerFiltered(newFormAnswers, user.id_user, filterRegister.conditions, filterRegister.searchText);
            newFormAnswers = getFormAnswerSorted(newFormAnswers, filterRegister.orderBy);

            const newColumnConfig = handleColumnsSize();

            getAggregations(newFormAnswers);

            setColumnConfig(newColumnConfig);
            setFormAnswersFiltered(newFormAnswers);

        }

    }, [filterRegister, formAnswers, handleColumnsSize, getAggregations, register, user.id_user]);

    useEffect(() => {
        const windowHeight = window.innerHeight;
        const newHeight = `${windowHeight - 190}px`; // Subtrai 100 para dar um pouco de margem
        setFixedHeaderHeight(newHeight);
    }, []);

    return (
        <LayoutContainer>
            <title>{register?.name !== undefined ? register.name : "Cadastro"} | Cange</title>
            <ListContainer>

                {/* Header Page */}
                <AppBarHeader
                    title={register?.name !== undefined ? register?.name : "Cadastro"}
                    icon={FaTable}
                    iconDynamic={iconRegister !== undefined ? iconRegister as IconList : "FaEllipsisH" as IconList}
                    iconDynamicColor={colorRegister !== undefined ? colorRegister : "#f23b5c"}
                    titleDynamic={nameRegister}
                    setTitleDynamic={!loading ? setNameRegister : undefined}
                    onBlueTitleDynamic={!loading ? handleSaveNameRegister : undefined}
                    disabledTitleDynamic={!getAccessControl(38, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "")}
                    maxLenTitle={150}
                    navBarItems={navBarItems}
                    itemNavBar={itemNavBarSelected}
                    onClickIconColorPicker={!loading ? handleOpenIconColorPicker : undefined}
                    isActiveDropDownMenu={true}
                    dropDownMenuChildren={
                        <>
                            {getAccessControl(38, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                                <Link to={'/register/1/' + params.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                    <DropDownItem title="Editar configurações do cadastro" icon={BsGearFill} color={"#f23b5c"} onClick={() => { }} />
                                </Link> :
                                <></>
                            }
                            {getAccessControl(39, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                                <Link to={'/register/2/' + params.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                    <DropDownItem title="Editar campos cadastro" icon={IoMdListBox} color={"#f23b5c"} onClick={() => { }} />
                                </Link> :
                                <></>
                            }
                            {getAccessControl(35, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                                <DropDownItem title="Gerenciar o acesso ao fluxo" icon={BsFillPeopleFill} color={"#9e37ed"} onClick={() => setOpenInviteUser(!openInviteUser)} /> :
                                <></>
                            }
                            {getAccessControl(71, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                                <>
                                    <DropDownDivider />
                                    <DropDownItem title="Exportar os dados" icon={BsFiletypeCsv} color={"#9e37ed"} onClick={getFeatureControlByPlan(2, user.company) ? () => downloadCSVData() : () => { }} isPaidFeature={!getFeatureControlByPlan(2, user.company)} idealPlan="Plano Plus" />
                                    <DropDownItem title="Imprimir esta visualização" icon={BsPrinter} color={"#9e37ed"} onClick={() => handlePrint()} />
                                </> :
                                <></>
                            }
                            <DropDownDivider />
                            {register !== undefined && register.user_creator !== undefined ?
                                <DropDownInfo style={{ paddingBottom: '0px' }}>
                                    <span>Criado por:</span> {register.user_creator.name}
                                </DropDownInfo> :
                                <></>
                            }
                            {register !== undefined && register.dt_created !== undefined ?
                                <DropDownInfo style={{ paddingBottom: '0px' }}>
                                    <span>Criado em:</span> {register.dt_created !== undefined ? formatDateToDateRead(new Date(register.dt_created)) : ""}
                                </DropDownInfo> :
                                <></>
                            }
                            {register !== undefined && register.isPrivate !== undefined && register.isPrivate === '1' ?
                                <DropDownInfo>
                                    <FaLock style={{ marginRight: '5px', color: 'gray' }} /> Fluxo privado
                                </DropDownInfo> :
                                <DropDownInfo>
                                    <FaLockOpen style={{ marginRight: '5px', color: 'gray' }} /> Fluxo público
                                </DropDownInfo>
                            }
                        </>
                    }
                >

                    {/* Novo Item */}
                    {getAccessControl(36, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                        <>
                            <ButtonNewItem
                                icon={FaPlus}
                                type="button"
                                color="#f23b5c"
                                height="36px"
                                margin="0px 15px 0px 0px"
                                onClick={() => setOpenNewAnswer(!openNewAnswer)}
                            >
                                {
                                    register?.button_add_name !== undefined &&
                                        register?.button_add_name !== null &&
                                        register?.button_add_name !== "" ?
                                        register?.button_add_name : "Novo Registro"
                                }
                            </ButtonNewItem>

                            <ButtonNewItemMobile className="new-item-btn" type="button" onClick={() => setOpenNewAnswer(!openNewAnswer)}>
                                <FaPlus />
                            </ButtonNewItemMobile>

                            <NewAnswer
                                open={openNewAnswer}
                                onSubmmit={dialogNewAnswerSubmmit}
                                onClose={dialogNewAnswerClose}
                                register={register}
                                typeUser={register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : ""}
                            />
                        </> : <></>
                    }

                    {getAccessControl(35, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                        <>
                            <ButtonUserAdd
                                icon={IoMdPersonAdd}
                                type="button"
                                color="#4680B8"
                                height="36px"
                                margin="0px 15px 0px 0px"
                                onClick={() => setOpenInviteUser(!openInviteUser)}
                            >
                                Convidar
                            </ButtonUserAdd>

                            <InviteUser
                                open={openInviteUser}
                                onClose={() => setOpenInviteUser(false)}
                                register={register}
                                typeUser={register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : ""}
                                title="Convide membros para o cadastro"
                            />
                        </> : <></>
                    }

                    {/* Configurar */}
                    {getAccessControl(37, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                        <>
                            <ButtonConfig
                                icon={BsGearFill}
                                type="button"
                                color="#f4f4f4"
                                height="36px"
                                margin="0px 15px 0px 0px"
                                color_text="gray"
                                onClick={() => setOpenRightPanel(true)}
                            >
                            </ButtonConfig>
                            <RightPanel
                                open={openRightPanel}
                                closePanel={() => setOpenRightPanel(false)}
                                title="">

                                <RightPanelItems>
                                    <RightPanelList>

                                        <FlowConfigGroup>
                                            <FlowConfigTitle>
                                                Configurações do cadastro
                                            </FlowConfigTitle>

                                            <FlowConfigItems>

                                                {getAccessControl(38, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                                                    <Link to={'/register/1/' + params.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                                        <FlowConfigItem color="#f23b5c">
                                                            <FlowConfigItemIcon color="#f23b5c">
                                                                <BsGearFill />
                                                            </FlowConfigItemIcon>
                                                            <FlowConfigItemDescription>
                                                                <h3>Configurações gerais</h3>
                                                                <span>Altere as informações gerais deste cadastro</span>
                                                            </FlowConfigItemDescription>
                                                        </FlowConfigItem>
                                                    </Link> : <></>
                                                }

                                                {getAccessControl(39, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                                                    <Link to={'/register/2/' + params.hash + "/edit"} style={{ textDecoration: 'none' }}>
                                                        <FlowConfigItem color="#61bd4f">
                                                            <FlowConfigItemIcon color="#61bd4f">
                                                                <IoMdListBox />
                                                            </FlowConfigItemIcon>
                                                            <FlowConfigItemDescription>
                                                                <h3>Campos do cadastro</h3>
                                                                <span>Edite os campos deste cadastro</span>
                                                            </FlowConfigItemDescription>
                                                        </FlowConfigItem>
                                                    </Link> : <></>
                                                }

                                            </FlowConfigItems>

                                        </FlowConfigGroup>

                                        <FlowConfigGroup style={{ marginTop: '20px' }}>
                                            <FlowConfigTitle>
                                                Ferramentas
                                            </FlowConfigTitle>

                                            <FlowConfigItems>

                                                {getAccessControl(40, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                                                    <FlowConfigItem color="#ff8c2f" onClick={() => configCardOpen()}>
                                                        <FlowConfigItemIcon color="#ff8c2f">
                                                            <IoMdSwitch />
                                                        </FlowConfigItemIcon>
                                                        <FlowConfigItemDescription>
                                                            <h3>Configurar exibição</h3>
                                                            <span>Configure a exibição do cartão nas conexões</span>
                                                        </FlowConfigItemDescription>
                                                    </FlowConfigItem> : <></>
                                                }

                                                {getAccessControl(68, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") && register !== undefined && register.form_id !== undefined ?
                                                    <>
                                                        <FlowConfigItem color="#ff8c2f" onClick={() => {
                                                            setOpenImporterData(true);
                                                            setOpenRightPanel(false);
                                                        }}>
                                                            <FlowConfigItemIcon color="#795548">
                                                                <TbDatabaseImport />
                                                            </FlowConfigItemIcon>
                                                            <FlowConfigItemDescription>
                                                                <h3>Importar dados</h3>
                                                                <span>Importe dados a partir de um arquivo</span>
                                                            </FlowConfigItemDescription>
                                                        </FlowConfigItem>
                                                        {openImporterData ?
                                                            <ImporterData
                                                                open={openImporterData}
                                                                onClose={() => setOpenImporterData(false)}
                                                                form_id={register?.form_id}
                                                                register_id={register.id_register}
                                                            /> :
                                                            <> </>
                                                        }
                                                    </>
                                                    : <></>
                                                }

                                            </FlowConfigItems>

                                        </FlowConfigGroup>

                                        <FlowConfigGroup style={{ marginTop: '20px' }}>
                                            <FlowConfigTitle>
                                                Opções Avançadas
                                            </FlowConfigTitle>

                                            <FlowConfigItems>

                                                {getAccessControl(41, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") ?
                                                    <FlowConfigItem color="#e24d4b" onClick={() => setStateDelRegister(true)}>
                                                        <FlowConfigItemIcon color="#e24d4b">
                                                            <FiTrash2 />
                                                        </FlowConfigItemIcon>
                                                        <FlowConfigItemDescription>
                                                            <h3>Excluir o cadastro</h3>
                                                            <span>Cuidado! Exclua o cadastro e os seus dados</span>
                                                        </FlowConfigItemDescription>
                                                    </FlowConfigItem> : <></>
                                                }

                                            </FlowConfigItems>
                                        </FlowConfigGroup>

                                    </RightPanelList>
                                </RightPanelItems>

                            </RightPanel>
                            <ConfigCard
                                onClose={configCardClose}
                                open={openConfigCard}
                                register={register}
                            />
                            <DialogAction
                                open={stateDelRegister}
                                message={"Você tem certeza que deseja excluir este cadastro?"}
                                actions_items={[
                                    "Exclusão de todos os dados deste cadastro;",
                                    "Exclusão de todas as conexões com o cadastro;",
                                    "Exclusão de todas as informações do cadastro"
                                ]}
                                disclaimer={"Lembrando que após a confirmação não será mais possível recuperar os dados referente ao cadastro"}
                                onClose={() => setStateDelRegister(false)}
                                onSubmmit={dialogDelRegisterSubmmit}
                                isLoading={loadingDelRegister}
                                type={1}
                            />
                        </> : <></>
                    }

                    {openIconColorPicker && getAccessControl(38, register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : "") &&
                        <IconColorPicker
                            key={"icon-color-picker"}
                            open={openIconColorPicker}
                            icon={iconRegister}
                            color={colorRegister}
                            top={55}
                            left={10}
                            index={0}
                            handleClose={handleCloseIconColorPicker}
                            setColor={setColorRegister}
                            setIcon={setIconRegister}
                        />
                    }

                </AppBarHeader>

                <FilterBarRegister
                    register={register}
                    itemNavBar={itemNavBarSelected}
                    typeUser={register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : ""}
                    onFilterRegister={onFilterRegister}
                    initFilterRegister={initFilterRegister}
                />

                {/* Container Body */}
                {loading ?
                    <LoaderContainer>
                        <LoaderTable />
                    </LoaderContainer> :
                    <ContainerBody>
                        <ContainerContent ref={printComponentRef}>

                            {/* Aggregation */}
                            {aggregateItems !== undefined && aggregateItems.length > 0 ?
                                <AggregationContainer>
                                    {aggregateItems.map((item) => (
                                        <AggregationItem key={item.columnName + item.typeField} color={register !== undefined && register.color !== undefined ? register.color : "#9e37ed"}>
                                            <AggregateItemLeft>
                                                <AggregationName>
                                                    <AggregationNameText>{item.columnName}</AggregationNameText>
                                                    <AggregationNameType>
                                                        {"(" + item.typeAggregate + ")"}
                                                    </AggregationNameType>
                                                </AggregationName>
                                                <AggregationValue>
                                                    {item.value !== undefined ? getMaskField(item.typeField, item.value, item.variation) : "0"}
                                                </AggregationValue>
                                            </AggregateItemLeft>
                                            <AggregateItemRight>
                                                <AggregateConfig className="aggregateConfig" onClick={() => configCardOpen()}>
                                                    <BsFillGearFill />
                                                </AggregateConfig>
                                            </AggregateItemRight>
                                        </AggregationItem>
                                    ))}
                                </AggregationContainer> : <></>
                            }

                            {/* Data List */}
                            {formAnswersFiltered !== undefined && register !== undefined && columnConfig !== undefined && formAnswersFiltered.length > 0 ?
                                <DataList style={{ overflowX: "scroll", overflowY: "hidden", position: "relative", maxWidth: "100%", borderRadius: '10px' }}>
                                    <DataTableCange
                                        className="tableCange"
                                        columns={columnConfig}
                                        data={formAnswersFiltered}
                                        subHeaderAlign={subHeaderAlign}
                                        noHeader={noHeader}
                                        customStyles={customStyles}
                                        striped={true}
                                        highlightOnHover
                                        onRowClicked={onRowClicked}
                                        noDataComponent={<div style={{ color: 'black', margin: '10px', padding: '20px', fontWeight: '500' }}>Não encontramos nenhum registro com os filtros escolhidos!</div>}
                                        pointerOnHover
                                        sortServer={false}
                                        fixedHeader={true}
                                        fixedHeaderScrollHeight={fixedHeaderHeight}
                                    />
                                    {selectedRecord !== undefined && register.form !== undefined ?
                                        <Record
                                            open={openRecord}
                                            selectedValue={selectedRecord}
                                            id_register={register.id_register}
                                            form={register.form}
                                            name={register.name}
                                            color={register.color}
                                            icon={register.icon}
                                            hash={register.hash}
                                            typeUser={register !== undefined && register.typeUserAccess !== undefined ? register.typeUserAccess : ""}
                                            onClose={() => setOpenRecord(!openRecord)}
                                        /> :
                                        <></>}
                                </DataList> :
                                <><EmptyCard /></>
                            }
                        </ContainerContent>
                    </ContainerBody>}

            </ListContainer >
        </LayoutContainer >

    );
};

export default RegisterPage;