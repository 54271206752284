import {
    Address,
    AddressValue,
    BetaSpan,
    BoxSearchContainer,
    BreakArea,
    ButtonCancel,
    ButtonConfirm,
    ButtonDelete,
    ContainerAdrress,
    Content,
    ContentBody,
    ContentPreviewStd,
    CopyBtn,
    DetailsField,
    DialogFooter,
    DialogFooterLeft,
    DialogFooterRight,
    FTypeItem,
    FTypeItemDescription,
    FTypeItemHeader,
    FilterComboContainer,
    FilterComboContainerButton,
    FilterComboContainerDescription,
    FilterComboContainerTitle,
    FormField,
    HelpContainer,
    HelpIcon,
    HelpItem,
    HelpItems,
    HelpText,
    ListTypeField,
    PickField,
    PreviewField,
    SearchInput,
    SwitchBase,
    TitleAddress
} from "./styles";
import { FieldProps, Fields, TypeFieldProps, TypeFields, getComponentValidation, getComponentValidationType, getFieldObject, getItemComponentDescription, getTypeField } from "../../components/Forms/Fields/FieldBuilder";
import React, { useCallback, useEffect, useRef, useState } from "react";

import ConfirmDialog from "../ConfirmDialog";
import Description from "../../components/Description";
import { Dialog } from '@material-ui/core';
import { FlowFieldFormData } from "../../pages/NewFlow/SecStep";
import { FormHandles } from "@unform/core";
import { RiLightbulbFlashFill } from "react-icons/ri";
import Title from "../../components/Title";
import { getItemComponentConfig } from "../../utils/fieldTypesConfig";
import { useToast } from "../../hooks/toast";
import { TbCheck, TbCircleKey, TbCopy } from "react-icons/tb";
import { Flow } from "../../interfaces/Flow";
import { FaPlus } from "react-icons/fa";
import ConditionalComboBox from "../../components/Forms/Fields/Components/ConditionalComboBox";
import { Register } from "../../interfaces/Register";
import api from "../../services/api";
import { MdOutlineFilterList } from "react-icons/md";

export interface NewFieldDialogProps {
    index: number;
    open: boolean;
    origin: 'flow' | 'register';
    selectedValue: FieldProps;
    type: string; //INS - Inserindo, EDI - Editando
    formFields?: FieldProps[];
    flow?: Flow;
    register?: Register;
    isFormInit?: boolean;
    onSubmmit: (data: FieldProps) => Promise<void>;
    onClose: (value: FieldProps) => void;
    onDelete?: (name: string, id_field?: number) => Promise<void>;
}

interface FieldOptionsProps {
    id_field_option?: number;
    field_id?: number;
    value: string;
    label: string;
    order?: string;
    hide?: string;
    old_id_field_option?: number;
}

const fieldInit: FieldProps = {
    index: 0,
    name: "field_title",
    type: Fields.TEXT_SHORT_FIELD,
    required: false,
    title: "",
    description: ""
}

const NewField: React.FC<NewFieldDialogProps> = ({ index, onSubmmit, onClose, onDelete, selectedValue, open, type, formFields, origin, flow, register, isFormInit }) => {

    const formRefConfig = useRef<FormHandles>(null);
    const formRefPreview = useRef<FormHandles>(null);

    const { addToast } = useToast();

    const [selectedIndex, setSelectedIndex] = useState("TEXT_SHORT_FIELD");
    const [initValueFormConfig, setInitValueFormConfig] = useState({});
    const [field, setField] = useState(fieldInit);
    const [fieldConfig, setFieldConfig] = useState(getItemComponentConfig(field.type));
    const [copied, setCopied] = useState<boolean>(false);

    const [sec, setSec] = useState(0);
    const [action, setAction] = useState("INS");

    const [fieldsTypes, setFieldsTypes] = useState(TypeFields as TypeFieldProps[]);

    //Conditional Filter
    const [switchState, setSwitchState] = useState(selectedValue.filter_schema !== undefined && selectedValue.filter_schema !== null && selectedValue.filter_schema !== "" ? true : false);
    const [openFilterCombo, setOpenFilterCombo] = useState(false);
    const [registerConnected, setRegisterConnected] = useState<Register>();
    const [flowConnected, setFlowConnected] = useState<Flow>();
    const [filterSchema, setFilterSchema] = useState<string | undefined>(selectedValue.filter_schema !== undefined && selectedValue.filter_schema !== null ? selectedValue.filter_schema : undefined);

    //Dialog Delete
    const [messageDlgDel, setMessageDlgDel] = useState("");
    const [openDlgDel, setOpenDlgDel] = useState(false);

    //Search
    const [search, setSearch] = useState<string>("");
    const searchInputRef = useRef<HTMLInputElement>(null);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(field.name);

        setCopied(true);
    };

    const switchHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSwitchState(event.target.checked);
    };

    const getRegisterObject = useCallback((register_id?: number) => {

        //Get the main register
        if (register_id !== undefined && register_id !== null && register_id > 0) {

            let newRegisterId = register_id;
            if (typeof register_id === "object") {
                newRegisterId = register_id[0];
            } else {
                newRegisterId = register_id;
            }

            api.get(`/register`, {
                params: {
                    id_register: newRegisterId,
                    withAnswers: false,
                    withAutoComplete: true
                }
            }).then(response => {
                if (response.data !== null) {
                    let retRegister: Register = response.data;
                    setRegisterConnected(retRegister);
                }
            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Não foi possível carregar o cadastro',
                    description: 'Ocorreu um erro ao carregar o cadastro!',
                });
            });
        }

    }, [addToast]);

    const getFlowObject = useCallback((flow_id?: number) => {

        //Get the main flow
        if (flow_id !== undefined && flow_id !== null) {
            let newFlowId = flow_id;
            if (typeof flow_id === "object") {
                newFlowId = flow_id[0];
            } else {
                newFlowId = flow_id;
            }

            api.get(`/flow/`, {
                params: {
                    id_flow: newFlowId,
                }
            }).then(response => {
                if (response.data !== null) {
                    let retFlow: Flow = response.data;
                    setFlowConnected(retFlow);
                }
            }).catch(error => {
                console.log(error);
                addToast({
                    type: 'error',
                    title: 'Não foi possível carregar o fluxo',
                    description: 'Ocorreu um erro ao carregar o fluxo!',
                });
            });
        }

    }, [addToast]);

    const getConnectionObject = useCallback(async () => {

        if (field.register_id !== undefined && field.register_id !== null && field.register_id > 0) {
            getRegisterObject(field.register_id);
        } else if (field.flow_id !== undefined && field.flow_id !== null && field.flow_id > 0) {
            getFlowObject(field.flow_id);
        }

    }, [field.register_id, field.flow_id, getRegisterObject, getFlowObject]);

    const changeFieldConfig = useCallback((type: string) => {

        let newItemComponentConfig = getItemComponentConfig(type);
        if (newItemComponentConfig !== undefined && newItemComponentConfig.length > 0) {
            newItemComponentConfig.map((ni) => {
                if (ni.type === "TEXT_FORMULA_FIELD") {
                    ni.formFields = formFields;
                } else if (ni.type === "CUSTOM_SELECT_FIELD") {

                    let newFields: FieldProps[] = [];
                    let newOptions = ni.options;
                    const flowBase = flow;

                    //Dynamic Text Field
                    if (ni.variation !== undefined && ni.variation === "6") {

                        //Form Init
                        if (flowBase !== undefined && flowBase.form_init !== undefined) {
                            if (flowBase.form_init.fields !== undefined && flowBase.form_init.fields.length > 0) {
                                flowBase.form_init.fields.forEach((field) => {
                                    newFields.push(field);
                                });
                            }
                        }

                        //Steps
                        if (flowBase !== undefined && flowBase.flow_steps !== undefined && flowBase.flow_steps.length > 0) {

                            flowBase.flow_steps.forEach((step) => {
                                if (step.form !== undefined && step.form.fields !== undefined && step.form.fields.length > 0) {
                                    step.form.fields.forEach((field) => {

                                        let newField = field;

                                        newField.group = step.name;
                                        newField.groupIndex = step.index;

                                        newFields.push(newField);
                                    });
                                }
                            });

                        }

                        if (newFields !== undefined && newFields.length > 0) {
                            newOptions = newFields.map((fieldItem) => {
                                return {
                                    value: String(fieldItem.id_field),
                                    label: fieldItem.title,
                                    icon: getFieldObject(fieldItem.type).icon,
                                    color: getFieldObject(fieldItem.type).color,
                                    description: getFieldObject(fieldItem.type).title,
                                    type: getFieldObject(fieldItem.type).type,
                                    group: fieldItem.group !== undefined ? fieldItem.group : "Formulário Inicial",
                                    groupIndex: fieldItem.groupIndex !== undefined ? fieldItem.groupIndex : -1
                                };
                            });
                        }
                    }

                    ni.options = newOptions;
                }
                return ni;
            });
        }
        setFieldConfig(newItemComponentConfig);

    }, [formFields, flow]);

    const dlgDelHandleOpen = useCallback(async () => {
        const msgDlg = "Você realmente deseja excluir o campo '" +
            field.title + "'? Esta ação não poderá ser revertida e você perderá " +
            "todos os dados vinculados ao campo"

        setMessageDlgDel(msgDlg);
        setOpenDlgDel(true);
    }, [field.title]);

    const dlgDelHandleClose = useCallback(async () => {
        setOpenDlgDel(false);
    }, []);

    const dlgDelHandleSubmmit = useCallback(async () => {
        setOpenDlgDel(false);

        //Caso já esteja no banco de dados
        if (onDelete !== undefined) {
            onDelete(field.name, selectedValue.id_field);
        }

    }, [field.name, selectedValue.id_field, onDelete]);

    const updatePreview = useCallback(async () => {

        const allData = formRefConfig.current?.getData();

        /* Title */
        if (allData !== undefined && allData["field_title"] !== undefined) {
            setField((prevState) => ({
                ...prevState,
                title: allData["field_title"]
            }));
        };

        /* Formula */
        if (allData !== undefined && allData["field_formula"] !== undefined) {
            setField((prevState) => ({
                ...prevState,
                formula: allData["field_formula"]
            }));
        };

        /* Register */
        if (allData !== undefined && allData["field_register_id"] !== undefined) {
            setField((prevState) => ({
                ...prevState,
                register_id: allData["field_register_id"]
            }));
            setField((prevState) => ({
                ...prevState,
                options: [{ value: allData["field_register_id"], label: allData["field_register_id"] }]
            }));
        }

        /* Flow */
        if (allData !== undefined && allData["field_flow_id"] !== undefined) {
            setField((prevState) => ({
                ...prevState,
                flow_id: allData["field_flow_id"]
            }));
            setField((prevState) => ({
                ...prevState,
                options: [{ value: allData["field_flow_id"], label: allData["field_flow_id"] }]
            }));
        }

        /* Variation */
        if (allData !== undefined && allData["field_variation"] !== undefined) {
            setField((prevState) => ({
                ...prevState,
                variation: allData["field_variation"]
            }));
        };

        /* Unique */
        if (allData !== undefined && allData["field_unique"] !== undefined) {
            setField((prevState) => ({
                ...prevState,
                unique: allData["field_unique"]
            }));
        };

        /* Options */
        if (allData !== undefined && allData["field_options"] !== undefined) {

            const optArr = allData["field_options"] as FieldOptionsProps[];

            if (optArr.length <= 0) {
                setField((prevState) => ({
                    ...prevState,
                    options: [{ value: "1", label: "Item 1" }]
                }));
            } else {
                setField((prevState) => ({
                    ...prevState,
                    options: allData["field_options"]
                }));
            }

        };

        /* Required */
        if (allData !== undefined && allData["field_required"] !== undefined) {
            setField((prevState) => ({
                ...prevState,
                required: allData["field_required"]
            }));
        };

        /* Max Lenght */
        if (allData !== undefined && allData["field_max_length"] !== undefined) {
            setField((prevState) => ({
                ...prevState,
                max_length: allData["field_max_length"]
            }));
        };

        /* On Edit */
        if (allData !== undefined && allData["field_on_edit"] !== undefined) {
            setField((prevState) => ({
                ...prevState,
                on_edit: allData["field_on_edit"]
            }));
        };

        /* Description */
        if (allData !== undefined && allData["field_description"] !== undefined) {
            setField((prevState) => ({
                ...prevState,
                description: allData["field_description"]
            }));
        };

        /* Help Text */
        if (allData !== undefined && allData["field_help_text"] !== false) {
            setField((prevState) => ({
                ...prevState,
                help_text: allData["field_help_text"]
            }));
        } else {
            setField((prevState) => ({
                ...prevState,
                help_text: undefined
            }));
        };

        /* Place Holder */
        if (allData !== undefined && allData["field_place_holder"] !== false) {
            setField((prevState) => ({
                ...prevState,
                placeholder: allData["field_place_holder"]
            }));
        };

        /* Updated */
        if (allData !== undefined && allData["field_updated"] !== false) {
            setField((prevState) => ({
                ...prevState,
                updated: allData["field_updated"]
            }));
        };

        /* Show On Form */
        if (allData !== undefined && allData["field_show_on_form"] !== false) {
            setField((prevState) => ({
                ...prevState,
                show_on_form: allData["field_show_on_form"]
            }));
        };

        /* Show On History */
        if (allData !== undefined && allData["field_show_on_history"] !== false) {
            setField((prevState) => ({
                ...prevState,
                show_on_history: allData["field_show_on_history"]
            }));
        };

        /* Preview Field */
        setField((prevState) => ({
            ...prevState,
            previewField: true
        }));


    }, [formRefConfig]);

    const handleClose = useCallback(async (event?: {}, reason?: "backdropClick" | "escapeKeyDown") => {

        if (reason !== "backdropClick") {
            onClose(selectedValue);
        }

    }, [onClose, selectedValue]);

    const handleAddField = useCallback(async () => {

        if (formRefConfig.current !== null) {
            formRefConfig.current.submitForm();
        }

    }, []);

    const handleListItemClick = useCallback(async (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>,
        id: string,
    ) => {
        setSelectedIndex(id);
        setField((prevState) => ({
            ...prevState,
            type: id
        }));
        changeFieldConfig(id);
    }, [changeFieldConfig]);

    /* Atualiza os dados do pre-view */
    const handleBlur = useCallback(async () => {
        updatePreview();
    }, [updatePreview]);

    function generateRandomNumber() {
        return Math.floor(Math.random() * 1000000); // Altere o limite conforme necessário
    }

    const handleValidCircularReferenceFlow = useCallback(async (flow_id: number, flow_parent_id: number): Promise<boolean> => {

        let ret = false;

        await api.get('/field/check-circular-ref/by-flow', {
            params: {
                flow_id: flow_id, //Who is adding the field
                flow_parent_id: Number(flow_parent_id) //Who is being added
            }
        }).then(response => {

            if (response.data.isAllowed !== null && response.data.isAllowed !== undefined) {
                ret = response.data.isAllowed;
                return ret;
            } else {
                return ret;
            }

        }).catch(error => {
            return ret;
        });

        return ret;

    }, []);

    const handleValidCircularReferenceRegister = useCallback(async (register_id: number, register_parent_id: number): Promise<boolean> => {

        let ret = false;

        await api.get('/field/check-circular-ref/by-register', {
            params: {
                register_id: register_id, //Who is adding the field
                register_parent_id: Number(register_parent_id) //Who is being added
            }
        }).then(response => {

            if (response.data.isAllowed !== null && response.data.isAllowed !== undefined) {
                ret = response.data.isAllowed;
                return ret;
            } else {
                return ret;
            }

        }).catch(error => {
            return ret;
        });

        return ret;

    }, []);

    const handleSubmmit = useCallback(async (data: object[]) => {

        var hash = require('object-hash');
        const dateNow = new Date().getTime();

        const dataNormalized = data as unknown as FlowFieldFormData;
        //const nameHash = await hash(dataNormalized.field_title, 8) as unknown as string;
        let nameHash = selectedValue.name;
        if (selectedValue.name === undefined) {
            nameHash = await hash(dataNormalized.field_title + '' + dateNow + '' + generateRandomNumber());
        }

        let validForm = true;
        let typeError; //0 - none / 1 - exclusive item / 2 - circular reference

        //Valid if the name is exclusive
        if (formFields !== undefined && dataNormalized.field_title !== undefined) {
            validForm = formFields?.filter((f) => f.title === dataNormalized.field_title.trim() && f.name !== nameHash).length > 0 ? false : true;
            if (!validForm) {
                typeError = 1;
            }
        }

        if (field.type === "COMBO_BOX_FLOW_FIELD" && flow !== undefined && flow.id_flow !== undefined) {
            validForm = await handleValidCircularReferenceFlow(flow.id_flow, dataNormalized.field_flow_id);
            if (!validForm) {
                typeError = 2;
            }
        } else if (field.type === "COMBO_BOX_REGISTER_FIELD" && register !== undefined && register.id_register !== undefined) {
            validForm = await handleValidCircularReferenceRegister(register.id_register, dataNormalized.field_register_id);
            if (!validForm) {
                typeError = 2;
            }
        }

        if (validForm) {
            let registerId: number | undefined = undefined;
            if (typeof dataNormalized.field_register_id === "object") {
                const idRegArr: Array<number> = dataNormalized.field_register_id as unknown as Array<number>;
                if (idRegArr !== undefined && idRegArr.length > 0) {
                    registerId = idRegArr[0];
                }

            }

            let flowId: number | undefined = undefined;
            if (typeof dataNormalized.field_flow_id === "object") {
                const idRegArr: Array<number> = dataNormalized.field_flow_id as unknown as Array<number>;
                if (idRegArr !== undefined && idRegArr.length > 0) {
                    flowId = idRegArr[0];
                }

            }

            const newField: FieldProps = {
                id_field: selectedValue.id_field,
                index: selectedValue.index >= 0 ? selectedValue.index : 0,
                name: nameHash,
                type: field.type,
                variation: dataNormalized.field_variation,
                title: dataNormalized.field_title !== undefined ? dataNormalized.field_title : getItemComponentDescription(field.type),
                description: dataNormalized.field_description,
                options: dataNormalized.field_options,
                placeholder: dataNormalized.field_place_holder,
                help_text: dataNormalized.field_help_text,
                required: dataNormalized.field_required, //boolean
                company_id: selectedValue.company_id,
                form_id: selectedValue.form_id,
                register_id: registerId !== undefined ? registerId : dataNormalized.field_register_id,
                flow_id: flowId !== undefined ? flowId : dataNormalized.field_flow_id,
                max_length: dataNormalized.field_max_length !== undefined ? Number(dataNormalized.field_max_length) : selectedValue.max_length,
                on_edit: Boolean(dataNormalized.field_on_edit),
                formula: dataNormalized.field_formula,
                updated: dataNormalized.field_updated,
                show_on_form: dataNormalized.field_show_on_form,
                show_on_history: dataNormalized.field_show_on_history,
                turn_on_field: selectedValue.turn_on_field,
                unique: dataNormalized.field_unique,
                user_id_creator: selectedValue.user_id_creator,
                validation_type: dataNormalized.field_required === true ? getComponentValidationType(field.type) : undefined,
                validations: dataNormalized.field_required === true ? getComponentValidation(field.type, dataNormalized.field_title) : undefined,
                filter_schema: filterSchema !== undefined ? filterSchema : undefined
            }

            onSubmmit(newField);

            if (formRefConfig.current !== null) {
                formRefConfig.current.reset();
            }

            if (formRefPreview.current !== null) {
                formRefPreview.current.reset();
            }
        } else {
            if (typeError === 1) {
                addToast({
                    type: 'error',
                    title: 'Este nome de campo já está sendo usado',
                    description: 'O nome do campo deve ser exclusivo, altere o nome para salvar!',
                });
            } else if (typeError === 2) {
                addToast({
                    type: 'error',
                    title: 'Adicionar este campo irá causar uma referência circular futura!',
                    description: 'Não é possível adicionar um campo que gere referencia circular!',
                });
            }
        }

    }, [field.type, onSubmmit, selectedValue, formFields, addToast, filterSchema, flow, register, handleValidCircularReferenceFlow, handleValidCircularReferenceRegister]);

    const handleSearch = useCallback(async (e: React.FormEvent<HTMLInputElement>) => {

        const searchText = e.currentTarget.value.toLowerCase();

        setSearch(searchText);

    }, []);

    useEffect(() => {

        setFieldsTypes(TypeFields);

        if (selectedValue.index >= 0 && open === true) { //Editando

            const tempInitForm = {
                field_title: selectedValue.title,
                field_variation: selectedValue.variation,
                field_description: selectedValue.description,
                field_options: selectedValue.options,
                field_place_holder: selectedValue.placeholder,
                field_help_text: selectedValue.help_text,
                field_required: selectedValue.required,
                field_register_id: selectedValue.register_id,
                field_flow_id: selectedValue.flow_id,
                field_max_length: selectedValue.max_length + "",
                field_on_edit: selectedValue.on_edit,
                field_formula: selectedValue.formula,
                field_unique: selectedValue.unique,
                field_updated: selectedValue.updated,
                field_show_on_form: selectedValue.show_on_form,
                field_show_on_history: selectedValue.show_on_history
            };

            if (selectedValue.id_field !== undefined) {
                if (selectedValue.id_field > 0) {
                    setFieldsTypes(TypeFields.filter((fil) => fil.id === selectedValue.type));
                }
            }

            setAction("EDI");
            setField(selectedValue);
            setInitValueFormConfig(tempInitForm);
            setSelectedIndex(selectedValue.type);
            changeFieldConfig(selectedValue.type);

        } else if (open === true) { //Novo

            setAction("INS");
            setField(fieldInit);
            setSelectedIndex(fieldInit.type);
            changeFieldConfig(fieldInit.type);
            setInitValueFormConfig({});

        }

        updatePreview();
    }, [selectedValue, updatePreview, open, formFields, changeFieldConfig]);

    //Keyboard Shortcuts
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter' && (event.ctrlKey || event.metaKey)) {
                handleAddField();
            }
        }

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleAddField]);

    const tick = useCallback(async () => {
        setSec(sec + 1);

        if (open === true) {
            updatePreview();
            setCopied(false);
        }

    }, [sec, open, updatePreview]);

    useEffect(() => {
        const timerId = setInterval(() => tick(), 1000);
        return () => clearInterval(timerId);
    });

    useEffect(() => {

        if (filterSchema !== undefined && filterSchema !== null && filterSchema !== "") {

            setSwitchState(true);

        } else {

            setSwitchState(false);

        }

    }, [filterSchema]);

    return (
        <Dialog
            fullWidth={true}
            maxWidth="lg"
            open={open}
            onClose={handleClose}
        >
            <Content>
                <ContentBody container>
                    {/* Pick a TypeField */}
                    <PickField item xs={3} md={3}>
                        <Title
                            title="Tipo do Campo"
                            required={false}
                            font_size="20px"
                            font_weight="600"
                        />
                        <Description>Escolha o tipo do campo</Description>

                        <ListTypeField>

                            <BoxSearchContainer>
                                <SearchInput
                                    placeholder="Buscar campos"
                                    defaultValue={search}
                                    onChange={handleSearch}
                                    ref={searchInputRef}
                                >
                                </SearchInput>
                            </BoxSearchContainer>

                            {fieldsTypes.filter((f) => {

                                //Apply search filter string by f.name
                                if (search !== undefined && search !== "") {

                                    if (f.name.toLowerCase().includes(search.toLowerCase())) {
                                        return true;
                                    } else {
                                        return false;
                                    }

                                }

                                if (origin === 'register' && (f.id === "COMBO_BOX_FLOW_FIELD" || f.id === "DUE_DATE_FIELD" || f.id === "ID_FIELD" || f.id === "DYNAMIC_TEXT_FIELD")) {
                                    return false;
                                } else if (origin === 'flow' && isFormInit === true && f.id === "DYNAMIC_TEXT_FIELD") {
                                    return false;
                                } else {
                                    return true;
                                }

                            }).map((type) => (
                                <FTypeItem
                                    color={type.color}
                                    key={type.id}
                                    selected={selectedIndex === type.id}
                                    onClick={(event) => handleListItemClick(event, type.id)}
                                >
                                    <FTypeItemHeader color={type.color}>
                                        <type.icon />
                                    </FTypeItemHeader>
                                    <FTypeItemDescription>
                                        {type.name}
                                        {type.isBeta ? (
                                            <BetaSpan>Beta</BetaSpan>
                                        ) : <></>}
                                        {type.isNew ? (
                                            <BetaSpan>Novo</BetaSpan>
                                        ) : <></>}
                                    </FTypeItemDescription>
                                </FTypeItem>
                            ))}
                        </ListTypeField>
                    </PickField>

                    {/* Details From Field */}
                    <DetailsField item xs={5} md={5}>
                        <Title
                            title="Configurações do Campo"
                            required={false}
                            font_size="20px"
                            font_weight="600"
                        />
                        <Description>Defina as configurações do campo</Description>

                        <FormField
                            id="configForm"
                            hideContainer={true}
                            formRef={formRefConfig}
                            fields={fieldConfig}
                            handleSubmit={handleSubmmit}
                            handleBlur={handleBlur}
                            initialValue={initValueFormConfig}
                        />

                        {field !== undefined && ((field.register_id !== undefined && field.register_id !== null && field.register_id > 0) || (field.flow_id !== undefined && field.flow_id !== null)) && (
                            <FilterComboContainer>
                                <FilterComboContainerTitle>
                                    Filtro da Busca
                                </FilterComboContainerTitle>
                                <SwitchBase
                                    checked={switchState}
                                    name={"SH-" + field.name}
                                    onChange={switchHandleChange}
                                    inputProps={{ "aria-label": "secondary checkbox" }}
                                />
                                <FilterComboContainerDescription>
                                    Adicione uma condição para filtrar os resultados da busca
                                </FilterComboContainerDescription>

                                {switchState && (
                                    <FilterComboContainerButton
                                        active={filterSchema !== undefined && filterSchema !== null && filterSchema !== "" ? true : false}
                                        onClick={() => {
                                            getConnectionObject();
                                            setOpenFilterCombo(true);
                                        }}
                                    >
                                        {filterSchema !== undefined && filterSchema !== null && filterSchema !== "" ? <MdOutlineFilterList /> : <FaPlus />}
                                        {filterSchema !== undefined && filterSchema !== null && filterSchema !== "" ? "Filtrando com regra condicional" : "Criar novo filtro condicional..."}
                                    </FilterComboContainerButton>
                                )}
                            </FilterComboContainer>
                        )}

                        {switchState && openFilterCombo && (
                            <ConditionalComboBox
                                open={openFilterCombo}
                                register={registerConnected}
                                flow={flowConnected}
                                filterSchema={filterSchema}
                                setFilterSchema={setFilterSchema}
                                onClose={(open: boolean) => setOpenFilterCombo(open)}
                            />
                        )}

                        {field !== undefined && field.id_field !== undefined && field.name !== undefined && (
                            <ContainerAdrress>
                                <TitleAddress>
                                    <TbCircleKey />
                                    <h3>Chave do Campo</h3>
                                </TitleAddress>
                                <AddressValue>
                                    <Address>{field.name}</Address>
                                    <CopyBtn onClick={handleCopyClick}>
                                        {copied ?
                                            <>
                                                <TbCheck />
                                                Copiado
                                            </> :
                                            <>
                                                <TbCopy />
                                                Copiar
                                            </>
                                        }
                                    </CopyBtn>
                                </AddressValue>
                            </ContainerAdrress>
                        )}
                    </DetailsField>

                    {/* Preview From Field */}
                    <PreviewField item xs={4} md={4}>
                        <Title
                            title="Pré-Visualização do Campo"
                            required={false}
                            font_size="20px"
                            font_weight="600"
                        />
                        <Description>Visualize como o campo irá ficar no formulário</Description>

                        {getTypeField(selectedIndex).help_text !== undefined ?
                            <HelpContainer>
                                <HelpItems>
                                    <HelpItem>
                                        <HelpIcon>
                                            <RiLightbulbFlashFill />
                                        </HelpIcon>
                                        <HelpText>
                                            <b>{"Dica: "}</b>{getTypeField(selectedIndex).help_text}
                                        </HelpText>
                                    </HelpItem>
                                </HelpItems>
                            </HelpContainer> :
                            <BreakArea />
                        }

                        <ContentPreviewStd>
                            <FormField id="previewForm" formRef={formRefPreview} fields={[field]}>
                            </FormField>
                        </ContentPreviewStd>
                    </PreviewField>
                </ContentBody>
            </Content>
            <DialogFooter>
                <DialogFooterLeft>
                    {action === "EDI" ?
                        <>
                            <ButtonDelete type="button" form="configForm" color="#e24d4b" onClick={() => dlgDelHandleOpen()}>Excluir</ButtonDelete>
                            <ConfirmDialog
                                open={openDlgDel}
                                message={messageDlgDel}
                                onClose={dlgDelHandleClose}
                                onSubmmit={dlgDelHandleSubmmit}
                                type={1}
                            />
                        </> :
                        <></>}
                </DialogFooterLeft>
                <DialogFooterRight>
                    <ButtonCancel type="button" color="#f4f4f4" onClick={() => handleClose()}>Cancelar</ButtonCancel>
                    <ButtonConfirm type="button" form="configForm" color="#2390eb" onClick={() => handleAddField()}>Salvar</ButtonConfirm>
                </DialogFooterRight>
            </DialogFooter>
        </Dialog >
    );
};

export default NewField;
