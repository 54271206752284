import React, { useCallback, useEffect, useRef, useState } from "react";

import {
    BoxContainer,
    BtnAddDynamicField,
    BtnExpandDialog,
    ButtonDynamicItem,
    Container,
    ContainerDynamic,
    ContainerLeft,
    ContainerRichText,
    ContainerRight,
    CopyBtn,
    ItemsSelected,
    OptionItem,
    OptionItemDescription,
    OptionItemIcon,
    OptionItemSelected,
    OptionItemSelectedBtn,
    OptionItemSelectedIcon,
    OptionItemSelectedPlaceHolder,
    OptionItemSelectedTitle,
    OptionTitle
} from "./style";
import { useField } from "@unform/core";
import ErrorDescription from "../../../../ErrorDescription";
import { HiOutlinePlus } from "react-icons/hi";
import { IconBaseProps } from "react-icons";
import { ClickAwayListener } from "@material-ui/core";
import { FaExpandAlt, FaPlug } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";
import { IconList, IconPickerItem } from "react-fa-icon-picker";
import Quill from "quill";
import ReactQuill from "react-quill";
import { TbCheck, TbCopy } from "react-icons/tb";
import ExpandDialog from "../../../../../dialogs/ExpandDialog";

export interface ComboOption {
    id_field_option?: number;
    field_id?: number;
    value: string;
    label: string;
    order?: string;
    hide?: string;
    old_id_field_option?: number;
    color?: string;
    icon?: React.ComponentType<IconBaseProps>;
    iconDynamic?: string;
    description?: string;
    type?: string;
    group?: string;
    groupIndex?: number;
}

interface CustomSelectProps {
    name: string;
    description?: string;
    variation?: string; // 1 - Text, 2 - E-mail, 3 - Rich Text, 4 - Only Select, 5 - Rich Text with Toolbar, 6 - Dynamic Config, 7 - Rich Text for JSON
    options?: ComboOption[];
    placeholder?: string;
    max_length?: number;
    disabled?: boolean;
    typeInput?: string;
    onForceBlur?: () => void;
}

const Embed = Quill.import('blots/embed');

class FieldBlot extends Embed {
    static create(value: { title: string, id_field: string, name: string, type: string, position: string, id?: string }) {
        let node = super.create()

        var hashLib = require('object-hash');
        const dateNowTime = new Date().getTime();
        const randomNumber = Math.floor(Math.random() * 1000);

        const hash = hashLib(dateNowTime + randomNumber + value.position + value.name);

        if (value.id !== undefined && value.id !== null) {
            node.setAttribute('id', value.id);
            node.setAttribute('href', value.id);
        } else {
            node.setAttribute('id', hash);
            node.setAttribute('href', hash);
        }

        node.setAttribute('type', 'field'); //type
        node.setAttribute('field-name', value.name);
        node.setAttribute('class', 'field-dynamic-tag');
        node.setAttribute('field-title', value.title);
        node.setAttribute('field-id', value.id_field);
        node.setAttribute('field-type', value.type);
        node.setAttribute('field-position', value.position);
        node.innerText = value.title;

        return node
    }

    static value(domNode: HTMLElement) {
        return {
            title: domNode.getAttribute('field-title'),
            id: domNode.getAttribute('id'),
            name: domNode.getAttribute('field-name'),
            type: domNode.getAttribute('type'),
            position: domNode.getAttribute('field-position'),
            href: domNode.getAttribute('href'),
            id_field: domNode.getAttribute('field-id'),
        }
    }
}

FieldBlot.attributes = ['href', 'field-name', 'field-title', 'field-id', 'field-type', 'field-position', 'class', 'id', 'type'];
FieldBlot.blotName = 'field';
FieldBlot.tagName = 'span';
FieldBlot.className = 'field-dynamic-tag';

Quill.register(FieldBlot, true);

const CustomSelect: React.FC<CustomSelectProps> = ({ name, description, variation, options, placeholder, max_length, onForceBlur, disabled, typeInput, ...rest }) => {

    const inputRef = useRef<HTMLInputElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [openSelector, setOpenSelector] = useState(false);
    const { fieldName, defaultValue, error, registerField } = useField(name);
    const [selectedItems, setSelectedItems] = useState<ComboOption[]>([]);
    const [isMultiple] = useState<boolean>(max_length !== undefined && max_length > 1 ? true : false);
    const [valueText, setValueText] = useState<string>();

    const [isInitObject, setIsInitObject] = useState<boolean>(false);
    const [openExpandDialog, setOpenExpandDialog] = useState(false);
    const [expandedValue, setExpandedValue] = useState('');
    const [copied, setCopied] = useState<boolean>(false);

    const editorRef = useRef<ReactQuill>(null);

    const modulesQuill = {
        toolbar: false
    };

    const modulesQuillJSON = {
        toolbar: false
    };

    const modulesQuillComplete = {
        toolbar: [
            [{ 'size': ['small', false, 'large', 'huge'] }],

            ['bold', 'italic', 'underline', 'strike'],

            [{ 'color': [] }, { 'background': [] }],

            [{ 'align': [] }, { 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'list': 'check' }],

            ['blockquote', 'code-block', 'link'],
        ],
        history: {
            userOnly: true,
        },
    };

    const handleCopyClick = () => {
        if (valueText !== undefined) {
            navigator.clipboard.writeText(valueText);
            setCopied(true);
        }
    };

    const handleClickAway = () => {
        setOpenSelector(false);
    };

    const handleAddText = useCallback((title: string, id_field: string, name: string, type: string) => {

        if (editorRef.current !== null) {
            const quill = editorRef.current.getEditor();
            if (quill !== null) {

                let position: number = 0;

                const range = quill.getSelection();

                if (range !== null) {
                    position = range.index;
                } else {
                    position = quill.getLength();
                }

                const value = {
                    title: title,
                    id_field: id_field,
                    name: name,
                    type: type,
                    position: String(position)
                };

                quill.insertEmbed(position, 'field', value);
                quill.setSelection({ index: position, length: 0 });

            }
        }
    }, []);


    const handleInputFocused = useCallback(() => {
        setIsFocused(true);
    }, []);

    const handleInputBlur = useCallback(() => {
        setIsFocused(false);
    }, []);

    const handleAddItem = useCallback((item: ComboOption) => {

        if (variation === "3" || variation === "5" || variation === "6" || variation === "7") { //Rich text

            handleAddText(item.label, item.value, item.value, item.type !== undefined ? item.type : "TEXT_SHORT_FIELD")

        } else {
            if (!isMultiple) {
                setSelectedItems([item]);
            } else {
                setSelectedItems([...selectedItems, item]);
            }
        }

        setOpenSelector(false);

    }, [selectedItems, isMultiple, handleAddText, variation]);

    const handleRemoveItem = useCallback((item: ComboOption) => {

        setSelectedItems(selectedItems.filter((i) => i.value !== item.value));

    }, [selectedItems]);

    const groupOptions: { [groupName: string]: ComboOption[] } = options?.reduce(
        (acc, item) => {
            if (item.group !== undefined) {
                if (acc[item.group]) {
                    acc[item.group].push(item);
                } else {
                    acc[item.group] = [item];
                }
            }
            return acc;
        },
        {} as { [groupName: string]: ComboOption[] }
    ) || {};

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            getValue: () => {
                if (selectedItems !== undefined && selectedItems.length > 0) {
                    return selectedItems.map(({ value }) => value);
                } else {
                    return valueText;
                }
            },
            clearValue: () => {
                if (selectedItems !== undefined && selectedItems.length > 0) {
                    setSelectedItems([]);
                } else {
                    setValueText("");
                }
            }
        });
    }, [fieldName, registerField, valueText, selectedItems]);

    useEffect(() => {

        if (defaultValue !== undefined) {

            if (typeof defaultValue === "string") {
                setValueText(defaultValue);
            } else if (Array.isArray(defaultValue)) {
                const selectedItems = options?.filter((option) => {
                    return defaultValue.includes(option.value);
                });

                if (selectedItems !== undefined && selectedItems.length > 0) {
                    setSelectedItems(selectedItems);
                    setValueText("");
                }
            }

        }

        setIsInitObject(true);

    }, [defaultValue, options]);

    useEffect(() => {

        if (onForceBlur !== undefined) {
            onForceBlur();
        }

    }, [selectedItems, onForceBlur]);

    useEffect(() => {
        if (openExpandDialog) {
            setExpandedValue(valueText || '');
        }
    }, [openExpandDialog, valueText]);

    return (
        <>
            {variation === undefined || variation === "1" || variation === "2" || variation === "4" ?
                <Container className="container-input" isErrored={!!error} isFocused={isFocused}>
                    <ContainerLeft>
                        {(variation === undefined || variation === "1" || variation === "2") && selectedItems.length === 0 ?
                            <input
                                id={name}
                                onFocus={handleInputFocused}
                                onBlur={handleInputBlur}
                                onChange={(e) => setValueText(e.currentTarget.value)}
                                value={valueText}
                                defaultValue={defaultValue}
                                ref={inputRef}
                                disabled={disabled}
                                name={name}
                                placeholder={placeholder}
                                type={typeInput !== undefined ? typeInput : "text"}
                                {...rest}
                            /> :
                            <ItemsSelected>
                                {selectedItems.map((item, index) => {

                                    const Icon = item.icon !== undefined ? item.icon : FaPlug;

                                    return (
                                        <OptionItemSelected key={index}>
                                            <OptionItemSelectedIcon>
                                                {item.iconDynamic !== undefined ?
                                                    <IconPickerItem
                                                        icon={item.iconDynamic as IconList}
                                                        color={item.color !== undefined ? item.color : "#bbbbbb"}
                                                    /> :
                                                    <Icon />
                                                }
                                            </OptionItemSelectedIcon>
                                            <OptionItemSelectedTitle>{item.label}</OptionItemSelectedTitle>
                                            <OptionItemSelectedBtn onClick={() => handleRemoveItem(item)}>
                                                <AiOutlineClose />
                                            </OptionItemSelectedBtn>
                                        </OptionItemSelected>
                                    )
                                })}

                                {(selectedItems === undefined || selectedItems.length === 0) && (
                                    <OptionItemSelectedPlaceHolder onClick={() => setOpenSelector(true)}>
                                        {placeholder !== undefined ? placeholder : ""}
                                    </OptionItemSelectedPlaceHolder>
                                )}
                            </ItemsSelected>
                        }
                    </ContainerLeft>
                    <ContainerRight>
                        {disabled && (
                            <CopyBtn onClick={(e) => {
                                e.preventDefault()
                                handleCopyClick()
                            }}>
                                {copied ?
                                    <>
                                        <TbCheck />
                                        Copiado
                                    </> :
                                    <>
                                        <TbCopy />
                                        Copiar
                                    </>
                                }
                            </CopyBtn>
                        )}
                        {options !== undefined && options.length > 0 && (
                            <ButtonDynamicItem onClick={() => setOpenSelector(true)}>
                                <HiOutlinePlus />
                                {openSelector ? (
                                    <ClickAwayListener onClickAway={handleClickAway}>
                                        <BoxContainer>

                                            {Object.entries(groupOptions).sort(
                                                ([groupA, optionsA], [groupB, optionsB]) => {
                                                    if (optionsA[0].groupIndex === undefined) {
                                                        return -1;
                                                    }
                                                    if (optionsB[0].groupIndex === undefined) {
                                                        return 1;
                                                    }
                                                    return optionsA[0].groupIndex - optionsB[0].groupIndex;
                                                }
                                            ).map(([group, optionItems], index) => {

                                                return (
                                                    <div key={index} style={{ marginBottom: '15px' }}>
                                                        <OptionTitle>{group}</OptionTitle>
                                                        {optionItems !== undefined && optionItems.length > 0 && optionItems
                                                            .map((option, index) => {

                                                                const Icon = option.icon !== undefined ? option.icon : FaPlug;

                                                                return (
                                                                    <OptionItem
                                                                        key={index}
                                                                        color={option.color !== undefined ? option.color : "#bbbbbb"}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            handleAddItem(option)
                                                                        }}
                                                                    >
                                                                        <OptionItemIcon color={option.color !== undefined ? option.color : "#bbbbbb"}>
                                                                            {option.iconDynamic !== undefined ?
                                                                                <IconPickerItem
                                                                                    icon={option.iconDynamic as IconList}
                                                                                    color={option.color !== undefined ? option.color : "#bbbbbb"}
                                                                                /> :
                                                                                <Icon />
                                                                            }
                                                                        </OptionItemIcon>
                                                                        <OptionItemDescription>
                                                                            <h3>{option.label}</h3>
                                                                            <span>{option.description}</span>
                                                                        </OptionItemDescription>
                                                                    </OptionItem>
                                                                )
                                                            })}
                                                    </div>
                                                )
                                            })}
                                        </BoxContainer>
                                    </ClickAwayListener>
                                ) : null}
                            </ButtonDynamicItem>
                        )}
                    </ContainerRight>
                </Container> : variation === "3" || variation === "5" || variation === "6" || variation === "7" ?
                    <>
                        <ContainerDynamic>
                            <BtnAddDynamicField type="button" onClick={() => setOpenSelector(true)}>
                                <HiOutlinePlus style={{ fontSize: '14px' }} />
                                Campo Dinâmico
                                {openSelector ? (
                                    <ClickAwayListener onClickAway={handleClickAway}>
                                        <BoxContainer style={{ left: '0', right: 'auto' }}>

                                            {Object.entries(groupOptions).sort(
                                                ([groupA, optionsA], [groupB, optionsB]) => {
                                                    if (optionsA[0].groupIndex === undefined) {
                                                        return -1;
                                                    }
                                                    if (optionsB[0].groupIndex === undefined) {
                                                        return 1;
                                                    }
                                                    return optionsA[0].groupIndex - optionsB[0].groupIndex;
                                                }
                                            ).map(([group, optionItems], index) => {

                                                return (
                                                    <div key={index} style={{ marginBottom: '15px' }}>
                                                        <OptionTitle>{group}</OptionTitle>
                                                        {optionItems !== undefined && optionItems.length > 0 && optionItems
                                                            .map((option, index) => {

                                                                const Icon = option.icon !== undefined ? option.icon : FaPlug;

                                                                return (
                                                                    <OptionItem
                                                                        key={index}
                                                                        color={option.color !== undefined ? option.color : "#bbbbbb"}
                                                                        onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            handleAddItem(option)
                                                                        }}
                                                                    >
                                                                        <OptionItemIcon color={option.color !== undefined ? option.color : "#bbbbbb"}>
                                                                            {option.iconDynamic !== undefined ?
                                                                                <IconPickerItem
                                                                                    icon={option.iconDynamic as IconList}
                                                                                    color={option.color !== undefined ? option.color : "#bbbbbb"}
                                                                                /> :
                                                                                <Icon />
                                                                            }
                                                                        </OptionItemIcon>
                                                                        <OptionItemDescription>
                                                                            <h3>{option.label}</h3>
                                                                            <span>{option.description}</span>
                                                                        </OptionItemDescription>
                                                                    </OptionItem>
                                                                )
                                                            })}
                                                    </div>
                                                )
                                            })}
                                        </BoxContainer>
                                    </ClickAwayListener>
                                ) : null}
                            </BtnAddDynamicField>

                            {/* Substituir <div>Expandir</div> por um botão que abre o dialog */}
                            <BtnExpandDialog
                                type="button"
                                onClick={() => setOpenExpandDialog(true)}
                            >
                                <FaExpandAlt style={{ fontSize: '14px' }} />
                            </BtnExpandDialog>
                        </ContainerDynamic>
                        <ContainerRichText isErrored={!!error} isFocused={isFocused}>
                            {isInitObject && !openExpandDialog ?
                                <ReactQuill
                                    id="editor"
                                    ref={editorRef}
                                    theme="snow"
                                    formats={variation === "7" ? ["field", 'href', 'field-name', 'field-title', 'field-id', 'field-type', 'field-position', 'class', 'id', 'type', 'span'] : undefined}
                                    value={valueText}
                                    onFocus={handleInputFocused}
                                    onBlur={handleInputBlur}
                                    onChange={setValueText}
                                    modules={variation === "5" || variation === "6" ? modulesQuillComplete : variation === "7" ? modulesQuillJSON : modulesQuill}
                                    placeholder={placeholder !== undefined ? placeholder : "Digite aqui..."}
                                /> :
                                <></>
                            }
                        </ContainerRichText>

                        {/* Expanded Dialog */}
                        <ExpandDialog
                            open={openExpandDialog}
                            onClose={() => {
                                // Ao fechar, salvamos no "valueText" tudo que foi alterado no expandedValue
                                setValueText(expandedValue);
                                setOpenExpandDialog(false);
                            }}
                            title="Editor Expandido"
                        >
                            <ContainerDynamic style={{ minWidth: '400px' }}>
                                <BtnAddDynamicField type="button" onClick={() => setOpenSelector(true)}>
                                    <HiOutlinePlus style={{ fontSize: '14px' }} />
                                    Campo Dinâmico
                                    {openSelector ? (
                                        <ClickAwayListener onClickAway={handleClickAway}>
                                            <BoxContainer style={{ left: '0', right: 'auto' }}>

                                                {Object.entries(groupOptions).sort(
                                                    ([groupA, optionsA], [groupB, optionsB]) => {
                                                        if (optionsA[0].groupIndex === undefined) {
                                                            return -1;
                                                        }
                                                        if (optionsB[0].groupIndex === undefined) {
                                                            return 1;
                                                        }
                                                        return optionsA[0].groupIndex - optionsB[0].groupIndex;
                                                    }
                                                ).map(([group, optionItems], index) => {

                                                    return (
                                                        <div key={index} style={{ marginBottom: '15px' }}>
                                                            <OptionTitle>{group}</OptionTitle>
                                                            {optionItems !== undefined && optionItems.length > 0 && optionItems
                                                                .map((option, index) => {

                                                                    const Icon = option.icon !== undefined ? option.icon : FaPlug;

                                                                    return (
                                                                        <OptionItem
                                                                            key={index}
                                                                            color={option.color !== undefined ? option.color : "#bbbbbb"}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                handleAddItem(option)
                                                                            }}
                                                                        >
                                                                            <OptionItemIcon color={option.color !== undefined ? option.color : "#bbbbbb"}>
                                                                                {option.iconDynamic !== undefined ?
                                                                                    <IconPickerItem
                                                                                        icon={option.iconDynamic as IconList}
                                                                                        color={option.color !== undefined ? option.color : "#bbbbbb"}
                                                                                    /> :
                                                                                    <Icon />
                                                                                }
                                                                            </OptionItemIcon>
                                                                            <OptionItemDescription>
                                                                                <h3>{option.label}</h3>
                                                                                <span>{option.description}</span>
                                                                            </OptionItemDescription>
                                                                        </OptionItem>
                                                                    )
                                                                })}
                                                        </div>
                                                    )
                                                })}
                                            </BoxContainer>
                                        </ClickAwayListener>
                                    ) : null}
                                </BtnAddDynamicField>

                                {/* Substituir <div>Expandir</div> por um botão que abre o dialog */}
                                <BtnExpandDialog
                                    type="button"
                                    onClick={() => setOpenExpandDialog(!openExpandDialog)}
                                >
                                    <FaExpandAlt style={{ fontSize: '14px' }} />
                                </BtnExpandDialog>
                            </ContainerDynamic>
                            <ContainerRichText isErrored={!!error} isFocused={isFocused} style={{ width: '100%', minWidth: '400px' }}>
                                <ReactQuill
                                    id="editor"
                                    ref={editorRef}
                                    theme="snow"
                                    formats={variation === "7" ? ["field", 'href', 'field-name', 'field-title', 'field-id', 'field-type', 'field-position', 'class', 'id', 'type', 'span'] : undefined}
                                    value={valueText}
                                    onFocus={handleInputFocused}
                                    onBlur={handleInputBlur}
                                    onChange={setValueText}
                                    style={{ height: '100%', width: '100%', minWidth: '400px' }}
                                    modules={variation === "5" || variation === "6" ? modulesQuillComplete : variation === "7" ? modulesQuillJSON : modulesQuill}
                                    placeholder={placeholder !== undefined ? placeholder : "Digite aqui..."}
                                />
                            </ContainerRichText>
                        </ExpandDialog>
                    </>
                    : null
            }

            {error && <ErrorDescription title={error} />}
        </>
    );
}

export default CustomSelect;