import { ComboItem, ComboItemDetail } from "../components/ItemRegister";

import { Card } from "../interfaces/Card";
import { Flow } from "../interfaces/Flow";
import { getItemComponentIcon } from "../components/Forms/Fields/FieldBuilder";
import updateFormulaFromCard from "./Formula/updateFormulaFromCard";

export default function formAnswerToComboItemFromFlow(card: Card, flow: Flow): ComboItem {

    let newCard = card;

    //Title Rule
    const title: string = newCard.title === undefined ? "(Sem Título)" : newCard.title + "";

    //Update the formula from the card
    newCard = updateFormulaFromCard(card);

    //Color due date rule
    let colorDate, descDate, descDue;
    if (card.dt_due_string !== undefined) {
        if (card.status_dt_due === undefined) {
            colorDate = "";
            descDue = "Não programado";
        } else if (card.status_dt_due === 1) { //Today
            colorDate = "#ff8c2e";
            descDate = "Hoje"
            descDue = "Hoje";
        } else if (card.status_dt_due === 2) { //Tomorrow
            colorDate = "#fe8c2f";
            descDate = "Amanhã"
            descDue = "Amanhã";
        } else if (card.status_dt_due === 3) { //Due date X
            colorDate = "#4581b7";
            descDate = card.dt_due_string.substring(0, 5)
            descDue = "Próximo";
        } else if (card.status_dt_due === 4) { //No Due date
            colorDate = "#ff8c2e";
            descDue = "Não programado";
        } else if (card.status_dt_due === 0) { //Lated
            colorDate = "#ff0000d1";
            descDate = card.dt_due_string.substring(0, 5)
            descDue = "Em Atraso";
        }
    } else {
        descDue = "Não programado";
    }

    //Details Rule
    let detailsNew: ComboItemDetail[] = [];
    if (newCard.form_answers_highlitgh !== undefined) {
        for (let idxB = 0; idxB < newCard.form_answers_highlitgh.length; idxB++) {
            const highlights = newCard.form_answers_highlitgh[idxB];

            const detailNew: ComboItemDetail = {
                title: highlights.field.title,
                Icon: getItemComponentIcon(highlights.field.type),
                value: highlights.valueString,
                type: highlights.field.type,
                variation: highlights.field.variation,
                valueAttachment: highlights.valueAttachment
            }

            detailsNew.push(detailNew);

        }
    }

    let isFocused = false;
    if (card.card_users !== undefined && card.card_users.length > 0){
        if (card.card_users[0].isFocused === "S") {
            isFocused = true;
        }
    }

    const itemNew: ComboItem = {
        id_register: newCard.id_card,
        title: title,
        origin_id: flow.id_flow,
        hash: flow.hash,
        icon: flow.icon,
        color: flow.color,
        origin: flow.name + " [" + newCard.flow_step?.name + "]",
        originGroup: flow.name,
        details: detailsNew,
        user: card.user,
        step: card.flow_step,
        due_date: descDate,
        due_date_color: colorDate,
        due_date_completed: card.dt_due,
        due_date_status_string: descDue,
        complete: card.complete,
        focus: card.focus || isFocused ? true : false
    }

    return itemNew;

}