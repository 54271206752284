// styles.ts (ou .tsx se preferir)

import styled, { css, keyframes } from 'styled-components';
import Button from '../../../components/Button';
import background from '../../../assets/background_gradient.png';

interface ProgressBarProps {
  percent: number;
}

interface GroupTagProps {
  isSelected: boolean;
  color?: string;
}

interface GroupTagContainerProps {
  isSelected: boolean;
}

export const Container = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;    
  justify-content: center;
  background: url(${background}) no-repeat center;
  flex: 1;
  background-size: cover;

  @media (max-width: 750px) {
    background: none;
    background-color: white;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  border-radius: 25px;

  /* Substituindo 'rgb(21 27 38 / 8%)' por 'rgba(21, 27, 38, 0.08)' */
  box-shadow: 0 5px 20px 0 rgba(21, 27, 38, 0.08);

  width: 100%;
  height: auto;
  /* min-width: 550px; (comentado) */
  min-height: 500px;
  max-width: 700px;
  background-color: white;

  img {
    margin-bottom: 35px;        
    height: 72px;
    width: 370px;
  }

  @media (max-height: 550px) {
    /* 'unset' substituído por 'auto' ou 'none', mas aqui já está 'auto' */
    min-height: auto;
    max-width: none;
    overflow: auto;        
  }

  @media (max-width: 750px) {
    box-shadow: none;
    height: 100%;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  } 
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  overflow: auto;

  form {
    margin: 80px 0;
    min-width: 400px;
    text-align: center;

    h1 {
      color: black;
      margin-bottom: 35px;
    }

    button {
      margin-top: 60px;
    }
  }
`;

export const BackGround = styled.div`
  flex: 1;
  background-color: #f6f8f9;
  background-size: cover;
`;

export const Header = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  padding-top: 30px;
  padding-right: 30px;
  padding-left: 30px;
  padding-bottom: 20px;

  /* Substituindo #80808040 por RGBA */
  border-bottom: 1px solid rgba(128,128,128,0.25);

  @media (max-width: 800px) {
    padding: 20px;
    padding-left: 10px;
  }
`;

export const HeaderLeft = styled.div`
  width: 100%;

  h1 {
    font-size: 24px;
    color: black;
    font-weight: bold;
    font-family: Roboto, helvetica;
    letter-spacing: 0.8px;
  }

  h3 {
    color: #676879;
    text-align: left;
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    /* Substituindo margin-block-end: unset; por margin-bottom: 0; */
    margin-bottom: 0;
  }

  @media (max-width: 800px) {
    h1 {
      text-align: center;
      font-size: 16px;
    }

    h3 {
      text-align: center;
      font-size: 13px;
    }
  }
`;

export const HeaderRight = styled.div`
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  max-width: 150px;

  img {
    margin-bottom: 0px !important;
    height: auto;
    width: 170px;
  }

  .logo-mini-cange {
    display: none;        
  }

  @media (max-width: 800px) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 0px 0px 0px 0px;

    .logo-cange {
      display: none;    
    }

    .logo-mini-cange {
      display: flex;  
      width: 100%;
      max-width: 130px;
      height: auto;  
    }
  }
`;

export const Body = styled.div`
  display: table;
  justify-content: flex-start;
  align-items: flex-start; 
  width: 100%;
  padding: 30px;
  padding-bottom: 0px;

  h3 {
    animation: ${appearFromLeft} 1s;
    color: #676879;
    text-align: left;
    margin-top: 8px;
    font-weight: 400;
    font-size: 16px;
    /* Substituindo margin-block-end: unset; por margin-bottom: 0; */
    margin-bottom: 0;
    margin-bottom: 30px; /* se precisar manter o espaçamento final */

    @media (max-width: 800px) {
      text-align: center;
    }
  }

  @media (max-width: 750px) {
    height: auto;
  }
`;

export const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  overflow: auto;

  form {
    text-align: start;
    margin: 0px;
    padding: 0px;
    max-width: 400px;

    @media (max-width: 800px) {
      min-width: unset;
    }
  }

  form button {
    margin-top: 0px !important;
  }

  h2 {
    margin-left: 5px;
    font-size: 16px;
    color: black;
    font-weight: 500;
    font-family: Roboto, helvetica;
  }
`;

export const BtnStart = styled(Button)`
  margin-top: 0px !important;
  height: 45px !important;
  width: 130px;
  margin-left: 30px;
  margin-right: 15px;
  margin-bottom: 10px;

  background: rgba(147, 55, 237, 1);

  :hover {
    background: rgba(147, 55, 237, 0.7);
  }
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  padding: 10px;

  @media (max-width: 800px) {
    display: block;
  }
`;

export const BottomLeft = styled.div`
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: 800px) {
    width: 100%;

    div {
      width: 100%;
      margin-right: 15px;
    }
  }
`;

export const BottomRight = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-content: center;

  @media (max-width: 800px) {
    width: 100%;
    margin-top: 15px;

    button {
      width: 100%;
      margin-left: 15px;
    }
  }
`;

export const ProgressBar = styled.div`
  margin-left: 15px;
  width: 170px;
  height: 10px;

  /* Substituindo #80808040 por RGBA */
  border: 1px solid rgba(128,128,128,0.25);
  border-radius: 15px;
`;

export const ProgressBarPercent = styled.div<ProgressBarProps>`
  background-color: #f23b5c;
  width: 20%;
  height: 8px;
  border-radius: 15px;    

  ${(props) =>
    props.percent &&
    css`
      width: ${props.percent + '%'} !important;
    `}
`;

export const ContainerGroup = styled.div`
  margin-top: 10px;
  display: block;

  /* Adicionando o prefixo para text-size-adjust */
  -webkit-text-size-adjust: auto;
  text-size-adjust: auto;

  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;
  height: auto;
`;

export const GroupTag = styled.div<GroupTagProps>`
  display: inline-block;
  justify-content: flex-start;
  align-content: center;
  padding: 5px;

  /* Substituindo #80808040 por RGBA */
  border: 1px solid rgba(128,128,128,0.25);

  margin: 5px;
  border-radius: 15px;

  :hover {
    border: 1px solid gray;
    background-color: #ededed;

    div {
      color: white;

      svg {
        color: white;
      }
    }
  }

  ${(props) =>
    props.color &&
    css`
      :hover {
        border: 1px solid ${props.color};
        background-color: ${props.color};
      }
    `}

  ${(props) =>
    props.isSelected &&
    props.color &&
    css`
      border: 1px solid ${props.color};
      background-color: ${props.color};
      font-weight: 500;

      :hover {
        border: 1px solid ${props.color};
        background-color: ${props.color};
      }
    `}
`;

export const GroupTagContainer = styled.div<GroupTagContainerProps>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;

  ${(props) =>
    props.isSelected &&
    css`
      div {
        color: white;

        svg {
          color: white;
        }
      }
    `}

  :hover {
    div {
      color: white;

      svg {
        color: white;
      }
    }
  }
`;

export const GroupTagIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-right: 10px;
  margin-left: 10px;

  div {
    font-size: 15px !important;

    svg {
      font-size: 18px;
    }
  }
`;

export const GroupTagName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  font-size: 14px;
  color: black;
`;